import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from './DataProvider';
import { InputGroup, Button, Form, Modal } from 'react-bootstrap';
import { Table, Input } from 'antd';
import { InfoCircle, PlusSquareFill, Trash } from 'react-bootstrap-icons';
import { Alert } from 'react-bootstrap';

const Items = ({ showWelcomeMsg }) => {
  // console.log('run Items...');
  const {
    items,
    lists,
    addItem,
    updateItem,
    deleteItem,
    findMealsContainingItem,
    colorPref,
    updateUserPreffs,
  } = useContext(DataContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    locations: [],
  });
  const [currentItem, setCurrentItem] = useState(null);
  const [mealsWithItem, setMealsWithItem] = useState([]);
  const [filter, setFilter] = useState('');
  const [deletable, setDeletable] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showItemWelcomeMsg, setShowItemWelcomeMessage] = useState(false);

  const stores = lists.find((list) => list.id === 'Stores');
  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));
  const filteredData = sortedItems.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase())
  );

  useEffect(() => {
    if (showWelcomeMsg) {
      setShowItemWelcomeMessage(true);
    }
  }, [showWelcomeMsg]);

  const ackWelcomeMsg = () => {
    if (showWelcomeMsg) {
      updateUserPreffs({
        seenItemWelcomeMessage: true,
      });
    }
    setShowItemWelcomeMessage(false);
  };

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const openModal = (item) => {
    const selectedItem = items.find((i) => i.name === item);
    setMealsWithItem(findMealsContainingItem(item));
    setCurrentItem(selectedItem);
    setFormData(selectedItem || { name: '', locations: [] });
    setModalIsOpen(true);
    setDeletable(false);
    setErrorMessage('');
    setShowError(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
    setDeletable(false);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log('name:', name, 'value:', value, 'formData:', formData);
  //   setErrorMessage('');
  //   setShowError(false);
  //   if (name.indexOf('::') > -1) {
  //     const storeId = name.split('::')[1];
  //     const storeData = [
  //       ...formData.locations.map((location) =>
  //         location.store === storeId
  //           ? { ...location, aisle: Number(value) }
  //           : location
  //       ),
  //       ...(formData.locations.some((location) => location.store === storeId)
  //         ? []
  //         : [{ store: storeId, aisle: Number(value) }]),
  //     ];
  //     // console.log('storeData:', { storeData });
  //     setFormData({ ...formData, locations: storeData });
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }
  // };

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setErrorMessage('');
    setShowError(false);
    setFormData({ ...formData, [name]: value });
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    const storeId = name.split('::')[1];

    if (!value) {
      const storeData = formData.locations.filter(
        (location) => location.store !== storeId
      );
      setFormData({ ...formData, locations: storeData });
      // console.log('storeData:', storeData);
      return;
    }

    const storeData = [
      ...formData.locations.map((location) =>
        location.store === storeId
          ? { ...location, aisle: Number(value) }
          : location
      ),
      ...(formData.locations.some((location) => location.store === storeId)
        ? []
        : [{ store: storeId, aisle: Number(value) }]),
    ];
    // console.log('storeData:', { storeData });
    setFormData({ ...formData, locations: storeData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowError(false);
    setErrorMessage('');
    if (currentItem) {
      const { success, message } = await updateItem({
        ...formData,
        name: formData.name.trim(), // Trim name before updating
      });
      if (success) {
        closeModal();
      } else {
        setErrorMessage(message);
        setShowError(true);
      }
    } else {
      const { success, message } = await addItem({
        ...formData,
        name: formData.name.trim(), // Trim name before adding
      });
      if (success) {
        closeModal();
      } else {
        setErrorMessage(message);
        setShowError(true);
      }
    }
  };

  const handleRemove = async () => {
    deleteItem(formData);
    closeModal();
  };

  return (
    <div className="mt-4 mb-5 container-fluid">
      {showItemWelcomeMsg && (
        <Alert
          className="small"
          variant={colorPref}
          onClose={ackWelcomeMsg}
          dismissible
        >
          <Alert.Heading>
            <strong className="me-auto">Welcome to Items!</strong>
          </Alert.Heading>
          Items can be anything from a gallon of milk to a birthday candle, and
          associating them with a Store and Aisle number helps you stay
          organized when it's time to shop.
          <br />
          <br />
          Whether you're planning meals for the week, making a packing list, or
          just keeping track of your pantry staples, we've got you covered!
        </Alert>
      )}
      <div className="container mb-2">
        <div className="row">
          <div className={'col-4 fs-1 p-0 text-' + colorPref}>
            Items{' '}
            <sup>
              <InfoCircle
                size={12}
                className="pointer"
                onClick={() => setShowItemWelcomeMessage(true)}
              />
            </sup>
          </div>
          <div className="col-6 mt-2 p-0">
            <Input
              placeholder="Filter by name"
              onChange={handleInputChange}
              className="w-100"
            />
          </div>
          <div className={'col-2 p-0 mt-2 ml-auto text-end text-' + colorPref}>
            <PlusSquareFill
              id="plusSquare"
              onClick={() => openModal(null)}
              size={32}
              className="pointer"
            />
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        showSorterTooltip={false}
        rowKey={(item) => item.name}
        onRow={(item) => {
          return {
            onClick: () => openModal(item.name), // click row
          };
        }}
      />

      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>
              {currentItem ? 'Edit Item' : 'Add Item'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Control
                size="lg"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleNameChange}
                placeholder="Item Name"
                required
                autoComplete="off"
              />
              {showError && <div className="text-danger">{errorMessage}</div>}
            </Form.Group>

            <Form.Label className="fs-5">Where to find this item:</Form.Label>
            {stores?.places.some((store) => store.aisles.length > 0) ? (
              <>
                <div className="row">
                  <div
                    className="col-9 mb-1"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '.8em',
                      fontStyle: 'italic',
                    }}
                  >
                    <small className="text-muted">&nbsp;Store Name</small>
                  </div>
                  <div
                    className="col-3 mb-1"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '.8em',
                      fontStyle: 'italic',
                    }}
                  >
                    <small className="text-muted">&nbsp;Aisle</small>
                  </div>
                </div>
                {stores?.places.map(
                  (store) =>
                    store.aisles.length > 0 && (
                      <InputGroup key={store.name} className="mb-1 ig-stores">
                        <InputGroup.Text id={store.name}>
                          {store.name}
                        </InputGroup.Text>

                        <Form.Select
                          name={'STORE::' + store.name}
                          value={
                            formData.locations.find(
                              (location) => location.store === store.name
                            )?.aisle
                          }
                          onChange={handleLocationChange}
                        >
                          <option value=""></option>
                          {store.aisles
                            .sort((a, b) => a.num - b.num) // Sort aisles by aisle number
                            .map((aisle) => (
                              <option key={aisle.name} value={aisle.num}>
                                {aisle.num + ' ' + aisle.name}
                              </option>
                            ))}
                        </Form.Select>
                      </InputGroup>
                    )
                )}
              </>
            ) : (
              <div className="text-muted small">
                🔍 To make this item easier to find while shopping, please{' '}
                <Link to="/stores">add aisle descriptions</Link> in your stores.
              </div>
            )}

            {currentItem && (
              <>
                <Form.Label className="mt-2 fs-5">
                  Meals using this item:
                </Form.Label>
                <ul className="item-meal-list">
                  {mealsWithItem.length > 0 ? (
                    mealsWithItem.map((meal) => (
                      <li key={meal.id}>{meal.name}</li>
                    ))
                  ) : (
                    <li>No meals with this item on its Grocery List</li>
                  )}
                </ul>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Save
              </Button>

              {currentItem && !mealsWithItem.length > 0 && deletable && (
                <Button
                  variant={colorPref === 'danger' ? 'warning' : 'danger'}
                  className="mr-2"
                  onClick={handleRemove}
                >
                  Delete
                </Button>
              )}

              {currentItem && !mealsWithItem.length > 0 && !deletable && (
                <Button
                  variant={colorPref}
                  className="mr-2"
                  onClick={() => setDeletable(true)}
                >
                  <Trash size={16} />
                </Button>
              )}

              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Items;
