import TextArea from 'antd/es/input/TextArea';
import React from 'react';

const NotesTab = ({ notes, setNotes, editMode }) => {
  return (
    <div>
      {editMode ? (
        <TextArea
          autoSize={true}
          rows={4}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Add any meal notes here..."
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: notes?.replace(/(?:\r\n|\r|\n)/g, '<br />') || 'No notes',
          }}
        />
      )}
    </div>
  );
};

export default NotesTab;
