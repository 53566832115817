const MenuWeekPicker = ({ handleDaySelect, startDay }) => {
  return (
    <div className="mt-2 container-fluid text-center p-0 m-0">
      <form>
        <p className="new-menu-text">
          A menu begins on the day <b>OF</b> or the day <b>AFTER</b> your usual
          shopping day. The idea is that you will be buying groceries for next
          week's menu.
        </p>
        <p className="h5">What day starts your meal week?</p>
        <ul className="day_picker">
          <li>
            <input
              type="radio"
              id="sun"
              name="day"
              value="Sun"
              onChange={handleDaySelect}
              checked={'Sun' === startDay}
            />
            <label htmlFor="sun">Sun</label>
          </li>
          <li>
            <input
              type="radio"
              id="mon"
              name="day"
              value="Mon"
              onChange={handleDaySelect}
              checked={'Mon' === startDay}
            />
            <label htmlFor="mon">Mon</label>
          </li>
          <li>
            <input
              type="radio"
              id="tue"
              name="day"
              value="Tue"
              onChange={handleDaySelect}
              checked={'Tue' === startDay}
            />
            <label htmlFor="tue">Tue</label>
          </li>
          <li>
            <input
              type="radio"
              id="wed"
              name="day"
              value="Wed"
              onChange={handleDaySelect}
              checked={'Wed' === startDay}
            />
            <label htmlFor="wed">Wed</label>
          </li>
          <li>
            <input
              type="radio"
              id="thu"
              name="day"
              value="Thu"
              onChange={handleDaySelect}
              checked={'Thu' === startDay}
            />
            <label htmlFor="thu">Thu</label>
          </li>
          <li>
            <input
              type="radio"
              id="fri"
              name="day"
              value="Fri"
              onChange={handleDaySelect}
              checked={'Fri' === startDay}
            />
            <label htmlFor="fri">Fri</label>
          </li>
          <li>
            <input
              type="radio"
              id="sat"
              name="day"
              value="Sat"
              onChange={handleDaySelect}
              checked={'Sat' === startDay}
            />
            <label htmlFor="sat">Sat</label>
          </li>
        </ul>
      </form>
    </div>
  );
};

export default MenuWeekPicker;
