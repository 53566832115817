import { useContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { XLg, Plus, PlusSquareFill, InfoCircle } from 'react-bootstrap-icons';
import { Table, Input } from 'antd';
import { DataContext } from './DataProvider';
import { Alert, Modal, Form, Button, InputGroup } from 'react-bootstrap';

const Stores = ({ showWelcomeMsg }) => {
  const {
    lists,
    updateStore,
    addStore,
    removeStore,
    findItemsContainingStore,
    colorPref,
    updateUserPreffs,
  } = useContext(DataContext);
  const stores = lists.find((list) => list.id === 'Stores');
  // const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', type: '', aisles: [] });
  const [currentStore, setCurrentStore] = useState(null);
  const [itemsWithStore, setItemsWithStore] = useState([]);
  const [itemsListIsVisible, setItemsListIsVisible] = useState(false);
  const [showStorWelcomeMsg, setShowStorWelcomeMessage] = useState(false);
  const [filter, setFilter] = useState('');
  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    if (showWelcomeMsg) {
      setShowStorWelcomeMessage(true);
    }
  }, [showWelcomeMsg]);

  const ackWelcomeMsg = () => {
    if (showWelcomeMsg) {
      updateUserPreffs({
        seenStorWelcomeMessage: true,
      });
    }
    setShowStorWelcomeMessage(false);
  };

  // Check if stores is defined and has items
  const sortedStores = stores?.places
    ? [...stores.places].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  const filteredData = sortedStores.filter((store) =>
    store.name.toLowerCase().includes(filter.toLowerCase())
  );

  const columns = [
    {
      title: 'Store Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  const openModal = (store) => {
    const selectedStore = stores?.places?.find((s) => s.name === store);
    setItemsWithStore(findItemsContainingStore(store));
    setCurrentStore(selectedStore);
    setFormData(selectedStore || { name: '', type: '', aisles: [] });
    // setSortedAisles(selectedStore?.aisles.sort((a, b) => a.num - b.num));
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentStore(null);
  };

  const handleAddAisle = () => {
    setFormData({
      ...formData,
      aisles: [
        ...formData.aisles,
        { num: formData.aisles.length + 1, name: '' },
      ],
    });
  };

  const handleRemoveAisle = (index) => {
    const newAisles = formData.aisles.filter((_, i) => i !== index);
    setFormData({ ...formData, aisles: newAisles });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log('name:', name, 'value:', value, 'formData:', formData);

    if (name.startsWith('aisle-')) {
      const [field, index] = name.split('-').slice(1);
      const newAisles = formData.aisles.map((aisle, i) =>
        i === parseInt(index) ? { ...aisle, [field]: value } : aisle
      );
      setFormData({ ...formData, aisles: newAisles });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // const handleAisleChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log('name:', name, 'value:', value, 'formData:', formData);
  //   const [field, index] = name.split('-').slice(1);
  //   const newAisles = formData.aisles.map((aisle, i) =>
  //     i === parseInt(index) ? { ...aisle, [field]: value } : aisle
  //   );
  //   console.log('newAisles', newAisles);
  //   setFormData({ ...formData, aisles: newAisles });
  // };
  // const handleAisleChange = (e) => {
  //   const { name, value } = e.target;
  //   // console.log('name:', name, 'value:', value, 'formData:', formData);
  //   const [field, index] = name.split('-').slice(1);

  //   // Convert the value to a number if it's a number field
  //   const newValue = field === 'num' ? Number(value) : value;

  //   const newAisles = formData.aisles.map((aisle, i) =>
  //     i === parseInt(index) ? { ...aisle, [field]: newValue } : aisle
  //   );
  //   console.log('newAisles', newAisles);
  //   setFormData({ ...formData, aisles: newAisles });
  // };

  const handleAisleNameChange = (e) => {
    const { name, value } = e.target;
    // console.log('name:', name, 'value:', value, 'formData:', formData);
    const [field, index] = name.split('-').slice(1);
    const newAisles = formData.aisles.map((aisle, i) =>
      i === parseInt(index) ? { ...aisle, [field]: value } : aisle
    );
    setFormData({ ...formData, aisles: newAisles });
  };

  const handleAisleNumChange = (e) => {
    const { name, value } = e.target;
    // console.log('name:', name, 'value:', value, 'formData:', formData);
    const [field, index] = name.split('-').slice(1);

    // only positive numbers for the aisles
    if (isNaN(value) || value < 0) return;

    // Convert the value to a number if it's a number field
    // const newValue = field === 'num' ? Number(value) : value;

    const newAisles = formData.aisles.map((aisle, i) =>
      i === parseInt(index) ? { ...aisle, [field]: Number(value) } : aisle
    );
    // console.log('newAisles', newAisles);
    setFormData({ ...formData, aisles: newAisles });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStore) {
      updateStore(currentStore.name, formData);
    } else {
      addStore(formData);
    }
    closeModal();
  };

  const handleRemove = async () => {
    await removeStore(currentStore.name);
    closeModal();
  };

  const toggleVisibility = () => {
    setItemsListIsVisible(!itemsListIsVisible);
  };

  // const handleBackClick = () => {
  //   navigate('/lists');
  // };

  return (
    <div className="mt-4 mb-5 container-fluid">
      {showStorWelcomeMsg && (
        <Alert
          className="small"
          variant={colorPref}
          onClose={ackWelcomeMsg}
          dismissible
        >
          <Alert.Heading>
            <strong className="me-auto">Welcome to Stores!</strong>
          </Alert.Heading>
          Here, you can create and organize your shopping trips by store. Choose
          from a variety of store types that are used as tailored shopping list
          categories.
          <br />
          <br />
          Fine-tune your shopping experience by creating numbered aisles within
          each store, ensuring that your shopping lists are always perfectly
          organized.
        </Alert>
      )}
      <div className="container mb-2">
        <div className="row">
          <div className={'col-4 fs-1 p-0 text-' + colorPref}>
            {/* <ArrowLeftShort
              size={28}
              className="pointer"
              onClick={handleBackClick}
            /> */}
            Stores{' '}
            <sup>
              <InfoCircle
                size={12}
                className="pointer"
                onClick={() => setShowStorWelcomeMessage(true)}
              />
            </sup>
          </div>
          <div className="col-6 mt-2 p-0">
            <Input
              placeholder="Filter by name"
              onChange={handleInputChange}
              className="w-100"
            />
          </div>
          <div className={'col-2 p-0 mt-2 ml-auto text-end text-' + colorPref}>
            <PlusSquareFill
              id="plusSquare"
              onClick={() => openModal(null)}
              size={32}
              className="pointer"
            />
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        showSorterTooltip={false}
        rowKey={(store) => store.name}
        onRow={(store) => {
          return {
            onClick: () => openModal(store.name), // click row
          };
        }}
      />

      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>
              {currentStore ? 'Edit Store' : 'Add Store'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name: *</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type: *</Form.Label>
              <Form.Select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a store type
                </option>
                {stores?.types?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Label>Aisles:</Form.Label>
            <div className="text-muted smaller mb-2">
              Never miss an item again!{' '}
              <b>
                Describe the types of items that can be found on each aisle.
              </b>{' '}
              Shopping lists can be sorted by aisle allowing you to group
              similar items for easy pickins in the store.
            </div>
            <div className="row">
              <div
                className="col-2 mb-1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '.8em',
                  fontStyle: 'italic',
                }}
              >
                <small className="text-muted ">&nbsp;#</small>
              </div>
              <div
                className="col-10 mb-1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '.8em',
                  fontStyle: 'italic',
                }}
              >
                <small className="text-muted">Description</small>
              </div>
            </div>
            {formData?.aisles
              // .sort((a, b) => a.num - b.num)
              .map((aisle, index) => (
                <div key={index}>
                  <InputGroup className="mb-1 flex-container store-aisle">
                    <Form.Control
                      className="aisle-input"
                      type="text"
                      name={`aisle-num-${index}`}
                      value={aisle.num}
                      onChange={handleAisleNumChange}
                      autoComplete="off"
                    />
                    <Form.Control
                      className="name-input"
                      type="text"
                      name={`aisle-name-${index}`}
                      value={aisle.name}
                      onChange={handleAisleNameChange}
                      autoComplete="off"
                    />
                    <Button
                      variant="outline-secondary"
                      // size="sm"
                      onClick={() => handleRemoveAisle(index)}
                    >
                      <XLg size={12} className="mb-1" />
                    </Button>
                  </InputGroup>
                </div>
              ))}
            <div className="d-grid gap-2">
              <Button variant={colorPref} onClick={handleAddAisle}>
                <Plus size={20} />
                Add Aisle
              </Button>
            </div>
            <Form.Label className="mt-3">
              {itemsWithStore.length} Items using this store:
            </Form.Label>
            <div onClick={toggleVisibility}>Show/Hide</div>
            <div
              className={`item-with-store ${
                itemsListIsVisible ? '' : 'd-none'
              }`}
            >
              <ul className="store-item-list">
                {itemsWithStore.length > 0 ? (
                  itemsWithStore
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => <li key={item.id}>{item.name}</li>)
                ) : (
                  <li className="small">
                    😥 <i>Sorry, no items are associated with this store</i>
                  </li>
                )}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Save
              </Button>
              {currentStore && !itemsWithStore.length > 0 && (
                <Button
                  variant={colorPref === 'danger' ? 'warning' : 'danger'}
                  className="mr-2"
                  onClick={handleRemove}
                >
                  Delete
                </Button>
              )}
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Stores;
