import { createContext, useState, useEffect } from 'react';
import { db, auth, functions, analytics } from '../firebase.config';
import { setUserId } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';

// Create the AuthContext
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [loadingMsg, setLoadingMsg] = useState('Logging in...');
  const [accountId, setAccountId] = useState(''); // used when joining an existing account
  const [accountReset, setAccountReset] = useState(false); // used when resetting an account

  // Function to get or create the current user's account
  const initUserAccount = async (email, acct, reset) => {
    if (!email) return null;
    if (reset) return await ResetUser(email);
    try {
      const userDoc = await getDoc(doc(db, 'users', email));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.accounts?.length > 0) {
          // return the first account on the account list
          return userData.accounts[0];
        } else {
          // the user's account list is empty
          return await CreateNewUser(email, acct);
        }
      } else {
        // this is a new user
        return await CreateNewUser(email, acct);
      }
    } catch (error) {
      console.error('Error initializing user account: ', error);
      return null;
    }
  };

  const CreateNewUser = async (email, acct) => {
    // create the user's account OR join an existing account...
    acct !== ''
      ? setLoadingMsg('Creating a new user and joining an existing account...')
      : setLoadingMsg('Creating a new user with a new account...');
    const createNewUserFunction = httpsCallable(functions, 'createUser');
    const acctId = (await createNewUserFunction({ email: email, acct: acct }))
      .data;
    if (acctId) {
      return acctId;
    } else {
      console.error('Error creating user account: ', acctId);
      setCurrentAccount(null);
      return null;
    }
  };

  const ResetUser = async (email) => {
    setLoadingMsg('Resetting Email & Creating a new account...');
    const resetUserFunction = httpsCallable(functions, 'resetUser');
    const acctId = (await resetUserFunction({ email: email })).data;
    if (acctId) {
      return acctId;
    } else {
      console.error('Error resetting user account: ', acctId);
      setCurrentAccount(null);
      return null;
    }
  };

  const removeAccount = async (account, email) => {
    console.log('removeAccount called');
    const removeAccountFunction = httpsCallable(functions, 'removeAccount');
    const response = (
      await removeAccountFunction({ account: account, email: email })
    ).data;
    if (response.success) {
      return true;
    } else {
      console.error('Error removing account: ', response);
      return false;
    }
  };

  useEffect(() => {
    // handle user authentication state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // console.log('authorized login: ' + user.email);
        setCurrentUser(user);
        setUserId(analytics, user.email);
        localStorage.setItem('loggedIn', true);
        setCurrentAccount(
          await initUserAccount(user.email, accountId, accountReset)
        );
      } else {
        setCurrentUser(null);
        setCurrentAccount(null);
        localStorage.removeItem('loggedIn');
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, accountReset]);

  // Context value to be provided
  const value = {
    currentUser,
    currentAccount,
    loadingMsg,
    setLoadingMsg,
    accountId,
    setAccountId,
    accountReset,
    setAccountReset,
    removeAccount,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
