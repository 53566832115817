import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AiTab = ({
  fetchMealByUrl,
  setName,
  setSides,
  setNotes,
  setIngredients,
  setInstructions,
  setShoppingList,
  setSourceUrl,
  sourceUrl,
  initialSourceUrl,
  colorPref,
}) => {
  const [errMessage, setErrMessage] = useState('');
  const [errVisible, setErrVisible] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [fetchButtonDisabled, setFetchButtonDisabled] = useState(false);
  const [fetchButtonText, setFetchButtonText] = useState('normal');
  const [fetchUrl, setFetchUrl] = useState('');

  const fetchMeal = async () => {
    setErrVisible(false);
    setFetchSuccess(false);

    if (fetchUrl.startsWith('http:') || fetchUrl.startsWith('https:')) {
      setFetchButtonDisabled(true);
      setFetchButtonText('spinner');

      try {
        const response = await fetchMealByUrl(fetchUrl);
        setFetchButtonText('normal');
        setFetchButtonDisabled(false);

        const newMeal = JSON.parse(response);

        if (newMeal?.meal) {
          if (newMeal.meal.name === 'FAILED') {
            const msg =
              newMeal.meal.message ||
              'Oh Snap! Meal not found. Check the URL and please try again.';
            setErrMessage(msg);
            setErrVisible(true);
          } else {
            setFetchSuccess(true);
            populateMealForm(newMeal?.meal);
          }
        } else {
          setErrMessage(
            'Oh Snap! Error with download. Check the URL and please try again.'
          );
          setErrVisible(true);
        }
      } catch (error) {
        setErrMessage(error);
        setErrVisible(true);
        console.error(error);
      }
    }
  };

  const populateMealForm = (mealData) => {
    // console.log('newMeal:', mealData.name);
    setName(mealData.name);
    setSides(mealData.sides || []);
    setShoppingList(
      mealData.groceries.map((item) => ({
        name: item,
        locations: [],
      }))
    );
    setIngredients(mealData.ingredients.map((item) => ({ name: item })));
    setInstructions(mealData.instructions.map((item) => ({ name: item })));
    setNotes(
      'Downloaded from the Web on ' +
        new Date().toLocaleDateString() +
        '\nServings: ' +
        mealData.servings
    );
    setSourceUrl(mealData.source_url);
  };

  return (
    <>
      {/* Render the foo div only when fetchUrl is empty on initial render */}
      {initialSourceUrl && (
        <div>
          <p className="h3">Downloaded from the Web</p>
          <p className="h6">Link to original recipe:</p>
          <a href={sourceUrl} target="_blank" rel="noreferrer">
            {sourceUrl}
          </a>
        </div>
      )}
      {!initialSourceUrl && (
        <div>
          <p className="h3">Download from the Web</p>
          <p className="h6">😊 Let Meal Buddy import the meal for you!</p>
          <p>
            Find a recipe on the public web, paste the URL here, and I'll add it
            for you.
          </p>
          <Form.Control
            id="ai-url"
            type="text"
            autoComplete="off"
            placeholder="Web page URL starting with http:// or https://"
            value={fetchUrl}
            onChange={(e) => setFetchUrl(e.target.value)}
          />

          {errVisible && (
            <div className="mt-3 text-danger">Failed! 😞 {errMessage}</div>
          )}

          {!fetchSuccess && (
            <>
              <Button
                id="fetchMeal-button"
                variant={colorPref}
                className="w-100 mt-3"
                onClick={fetchMeal}
                disabled={fetchButtonDisabled}
              >
                {fetchButtonText === 'normal' ? (
                  'Fetch My Meal'
                ) : (
                  <>
                    <div className="spinner-border text-light button-spinner"></div>
                    <span> Fetching Meal...</span>
                  </>
                )}
              </Button>
            </>
          )}

          {fetchSuccess && (
            <>
              <p className="fs-6 pt-2 text-success text-center border-bottom pb-2">
                <b>Success! Your meal has been added.</b>
              </p>
              <b>Consider the following before saving:</b>
              <ul className="mt-0 mb-1">
                <li>Review/Prune the Grocery List</li>
                <li>Review the Recipe</li>
                <li>Add some side dishes</li>
              </ul>
              <i className="small">
                *New Grocery List items are added when the meal is saved.
              </i>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AiTab;
