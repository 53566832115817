import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { XLg, Plus } from 'react-bootstrap-icons';
import CreatableSelect from 'react-select/creatable';

const ShoppingListTab = ({
  mealName,
  items,
  createItem,
  shoppingList,
  setShoppingList,
  colorPref,
  editMode,
}) => {
  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));
  const options = sortedItems.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const handleCreateOption = (inputValue) => {
    const newItem = { name: inputValue, locations: [] };
    createItem(newItem); // Add the new item to the items context
    setShoppingList([...shoppingList, { name: inputValue, locations: [] }]);
  };

  const handleInputChange = (index, selectedOption) => {
    const itemName = selectedOption.value;
    const locations = items.find((i) => i.name === itemName)?.locations || [];
    const newShoppingList = shoppingList.map((item, i) =>
      i === index ? { ...item, name: itemName, locations: locations } : item
    );
    setShoppingList(newShoppingList);
  };

  const handleRemoveItem = (index) => {
    setShoppingList(shoppingList.filter((_, i) => i !== index));
  };

  const handleAddItem = () => {
    setShoppingList([...shoppingList, { name: '', locations: [] }]);
  };

  return (
    <div>
      {!editMode ? (
        <div>
          {mealName.startsWith('lo:') ? (
            <div className="mb-0">
              This meal is a leftover and has no shopping items.
            </div>
          ) : (
            <>
              {shoppingList.length > 0 ? (
                <ul>
                  {shoppingList?.map((item) => (
                    <li key={item.name}>{item.name}</li>
                  ))}
                </ul>
              ) : (
                'No items in grocery list'
              )}
            </>
          )}
        </div>
      ) : (
        <>
          {shoppingList.map((item, index) => (
            <div key={index}>
              <InputGroup className="mb-1 flex-container">
                <CreatableSelect
                  className="flex-grow-1 meal-grocery-list-input"
                  value={
                    item.name
                      ? options.find(
                          (option) => option.value === item.name
                        ) || {
                          value: item.name,
                          label: item.name,
                        }
                      : null
                  }
                  onChange={(e) => handleInputChange(index, e)}
                  onCreateOption={handleCreateOption}
                  options={options}
                  placeholder="Select or create an item"
                  createOptionPosition="first"
                />
                <Button
                  variant="outline-secondary"
                  className="meal-grocery-list-input-button"
                  onClick={() => handleRemoveItem(index)}
                >
                  <XLg size={12} />
                </Button>
              </InputGroup>
            </div>
          ))}
          <div className="d-grid gap-2">
            <Button size="sm" variant={colorPref} onClick={handleAddItem}>
              <Plus size={20} />
              Add Item to Grocery List
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ShoppingListTab;
