import './App.css';
import { useContext, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { AuthContext } from './components/AuthProvider';
import { DataProvider, DataContext } from './components/DataProvider';
import { analytics } from './firebase.config';
import { logEvent } from 'firebase/analytics';
import SiteNav from './components/Website/SiteNav';
import Home from './components/Website/Home';
import Login from './components/Website/Login';
import LoginForm from './components/Website/LoginForm';
import Navbar from './components/Navbar';
import Menus from './components/Menus';
import Meals from './components/Meals/Meals';
import Lists from './components/Lists';
import Items from './components/Items';
import Settings from './components/Settings';
import ScrollToTop from './components/scrollToTop';
import Stores from './components/Stores';
import Shop from './components/Shop';
import History from './components/History';
import NewAccountLoader from './components/Utils/NewAccountLoader';
import Product from './components/Website/Product';
import About from './components/Website/About';
import Support from './components/Website/Support';
import Terms from './components/Website/Terms';
import Privacy from './components/Website/Privacy';

export default function App() {
  const {
    currentUser,
    currentAccount,
    loadingMsg,
    accountId,
    setAccountId,
    accountReset,
    setAccountReset,
  } = useContext(AuthContext);
  const loggedIn = localStorage.getItem('loggedIn') === 'true';

  if (!loggedIn) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SiteNav />}>
            <Route index element={<Home />} />
            <Route
              path="join"
              element={
                <Login
                  join={true}
                  useSearchParams={useSearchParams}
                  accountId={accountId}
                  setAccountId={setAccountId}
                />
              }
            />
            <Route
              path="login"
              element={
                <Login
                  join={false}
                  useSearchParams={useSearchParams}
                  accountReset={accountReset}
                  setAccountReset={setAccountReset}
                />
              }
            />
            <Route path="loginform" element={<LoginForm />} />
            <Route path="product" element={<Product />} />
            <Route path="about" element={<About />} />
            <Route path="support" element={<Support />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
        <PageViewAnalytics loggedIn={false} />
      </BrowserRouter>
    );
  }

  if (currentUser && currentAccount) {
    return (
      <BrowserRouter>
        <DataProvider currentUser={currentUser} currentAccount={currentAccount}>
          <ScrollToTop />
          <DataConsumer photoURL={currentUser?.photoURL} />
        </DataProvider>
        <PageViewAnalytics loggedIn={true} />
      </BrowserRouter>
    );
  } else if (loadingMsg.indexOf('account') > -1 && !currentAccount) {
    return (
      <div className="text-center mt-4">
        <p>{loadingMsg}</p>
        <NewAccountLoader />
      </div>
    );
  } else {
    return (
      <div className="text-center mt-4">
        <p>{loadingMsg}</p>
        <div className="spinner-border text-danger" role="status">
          <span className="visually-hidden">{loadingMsg}</span>
        </div>
      </div>
    );
  }
}

const DataConsumer = ({ photoURL }) => {
  const { loading, userPreffs } = useContext(DataContext);

  if (loading) {
    return (
      <div className="text-center mt-4">
        <p>Fetching account data...</p>
        <div className="spinner-border text-dark" role="status">
          <span className="visually-hidden">Fetching Data...</span>
        </div>
      </div>
    );
  }

  // Welcome messages - handle without using local storage
  const {
    seenMenuWelcomeMessage,
    seenMealWelcomeMessage,
    seenItemWelcomeMessage,
    seenShopWelcomeMessage,
    seenStorWelcomeMessage,
  } = userPreffs;

  const hasPrefs = Object.keys(userPreffs).length > 0;

  const showMenuWelcomeMsg = hasPrefs && !seenMenuWelcomeMessage ? true : false;
  const showMealWelcomeMsg = hasPrefs && !seenMealWelcomeMessage ? true : false;
  const showItemWelcomeMsg = hasPrefs && !seenItemWelcomeMessage ? true : false;
  const showShopWelcomeMsg = hasPrefs && !seenShopWelcomeMessage ? true : false;
  const showStorWelcomeMsg = hasPrefs && !seenStorWelcomeMessage ? true : false;

  return (
    <div id="app-container" className="app-container">
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route
            index
            element={<Menus showWelcomeMsg={showMenuWelcomeMsg} />}
          />
          <Route
            path="meals"
            element={<Meals showWelcomeMsg={showMealWelcomeMsg} />}
          />
          <Route
            path="items"
            element={<Items showWelcomeMsg={showItemWelcomeMsg} />}
          />
          <Route path="lists" element={<Lists />} />
          <Route
            path="/stores"
            element={<Stores showWelcomeMsg={showStorWelcomeMsg} />}
          />
          <Route
            path="shop"
            element={<Shop showWelcomeMsg={showShopWelcomeMsg} />}
          />
          <Route path="settings" element={<Settings photoURL={photoURL} />} />
          <Route path="history" element={<History />} />
          <Route path="loader" element={<NewAccountLoader />} />
          <Route path="*" element={<Menus />} />
        </Route>
      </Routes>
    </div>
  );
};

const PageViewAnalytics = ({ loggedIn }) => {
  const location = useLocation();

  useEffect(() => {
    var pathname = location.pathname;
    if (pathname === '/') {
      loggedIn ? (pathname = '/menu') : (pathname = '/home');
    }
    // console.log('run analytics...', pathname);
    // Log page view event
    logEvent(analytics, 'page_view', {
      page_title: pathname.charAt(1).toUpperCase() + pathname.slice(2),
      page_path: pathname,
    });
  }, [location, loggedIn]);

  return null;
};
