import { useContext, useState, useEffect } from 'react';
import { DataContext } from './DataProvider';
import { Link } from 'react-router-dom';
import { InputGroup, Button, ButtonGroup, Form, Modal } from 'react-bootstrap';
import { Table } from 'antd';
import Select from 'react-select';
import {
  ArrowRight,
  Dash,
  ExclamationCircle,
  Plus,
  ThreeDotsVertical,
  Trash,
} from 'react-bootstrap-icons';

const ListManager = ({ listType, shoppingMode }) => {
  const {
    items,
    lists,
    addListItem, // Generic function for adding items to a list
    bulkAddListItems, // Generic function for adding multiple items to a list
    changeListItem, // Generic function for updating an item on a list
    removeListItem, // Generic function for removing items from a list
    getItemByName,
    updateItem,
    menus,
    meals,
    userPreffs,
    colorPref,
    updateUserPreffs,
    updateHistory,
    updateStoreSortOrder,
  } = useContext(DataContext);

  const [selectedStore, setSelectedStore] = useState(null);
  const [hasAisles, setHasAisles] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [storeModalIsOpen, setStoreModalIsOpen] = useState(false);
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const [aisleModalIsOpen, setAisleModalIsOpen] = useState(false);
  const [addItemsModalIsOpen, setAddItemsModalIsOpen] = useState(false);
  const [origItemsList, setOrigItemsList] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [currentItemsList, setCurrentItemsList] = useState([]);
  const [clearListEnabled, setClearListEnabled] = useState(false);

  // form data when editing an item on the shopping list
  const [formData, setFormData] = useState({
    name: '',
    origName: '',
    qty: 1,
    origQty: 1,
    locations: [],
    origLocations: [],
  });

  // sort order preference for items on the shopping lists
  const [sortOrder, setSortOrder] = useState({
    columnKey: 'aisle',
    order: 'ascend',
  });

  useEffect(() => {
    const storePref = userPreffs?.['default_' + listType.toLowerCase()];
    const storeList = lists.find((list) => list.id === 'Stores');
    const firstAvailableStore = storeList.places.find(
      (store) => store.type === listType
    )?.name;

    const initialStore =
      storePref &&
      storeList.places.find((store) => store.name === storePref)?.type ===
        listType
        ? storePref
        : firstAvailableStore;

    setSelectedStore({
      value: initialStore,
      label: initialStore,
    });

    const savedSortOrder = storeList.places.find(
      (store) => store.name === initialStore
    )?.sortOrder;
    if (savedSortOrder) {
      setSortOrder(savedSortOrder);
    }

    setHasAisles(
      storeList.places.find((store) => store.name === initialStore)?.aisles
        ?.length > 0
    );

    // Find the relevant list from lists based on listType
    setCurrentList(lists.find((list) => list.id === listType));
  }, [listType, userPreffs, lists]);

  // List of stores with the specified listType
  const stores = lists.find((list) => list.id === 'Stores');
  const filteredStores = stores?.places
    ? [...stores.places].filter((store) => store.type === listType)
    : [];

  // Sort items and meals by name
  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));
  const sortedMeals = [...meals].sort((a, b) => a.name.localeCompare(b.name));

  // Handle adding a single list item
  const handleAddListItem = (item) => {
    addListItem(listType, item);
    setSelectedItem(null);
  };

  // Handle adding grocery items (if applicable) to the listType
  const handleAddItemsByModal = async () => {
    await bulkAddListItems(listType, currentItemsList);
    closeAddItemsModal();
  };

  const openAddItemsModal = (listOfItemsToAdd) => {
    // console.log('openAddItemsModal', listOfItemsToAdd);

    // Function to normalize item names
    // const normalizeName = (name) => name.trim().toLowerCase();

    // Function to remove duplicate items based on their normalized names
    // const removeDuplicates = (items) => {
    //   const seen = new Set();
    //   return items.filter((item) => {
    //     const normalizedName = normalizeName(item.name);
    //     if (seen.has(normalizedName)) {
    //       return false;
    //     } else {
    //       seen.add(normalizedName);
    //       return true;
    //     }
    //   });
    // };

    // var newItems = removeDuplicates(listOfItemsToAdd);
    var newItems = listOfItemsToAdd;

    newItems = newItems.sort((a, b) => a.name.localeCompare(b.name));
    setCurrentItemsList(newItems);
    setOrigItemsList(newItems);
    setAddItemsModalIsOpen(true);
  };

  const closeAddItemsModal = () => {
    setCurrentItemsList([]);
    setSelectedMeal(null);
    setAddItemsModalIsOpen(false);
  };

  const handleItemCheckbox = (itemName) => {
    // Find the index of the item in the currentItemsList
    const itemIndex = currentItemsList.findIndex(
      (item) => item.name === itemName
    );

    // If the item is already in the list, remove it
    if (itemIndex !== -1) {
      setCurrentItemsList(
        currentItemsList.filter((_, index) => index !== itemIndex)
      );
    } else {
      // Otherwise, add the item to the list
      const itemToAdd = origItemsList.find((item) => item.name === itemName);
      if (itemToAdd) {
        setCurrentItemsList([...currentItemsList, itemToAdd]);
      }
    }
  };

  // Handle selecting an item on the list. Edit if not in shopping mode.
  const handleSelectItem = (item) => {
    const storeHasAisles =
      stores.places.find((store) => store.name === selectedStore?.value)?.aisles
        .length > 0;
    const itemHasAisle = item.locations.find(
      (location) => location.store === selectedStore?.value
    )?.aisle;

    if (shoppingMode) {
      if (storeHasAisles && isNaN(itemHasAisle)) {
        // Offer to update the aisle when shopping
        openAisleModal(item);
      } else {
        handleRemoveListItem(item.name, item.qty);
      }
    } else {
      openItemModal(item);
    }
  };

  // Handle removing a list item
  const handleRemoveListItem = (item, qty) => {
    if (shoppingMode) {
      const updatedItem = {
        item: item,
        qty: qty || 1,
        store: selectedStore?.value,
        date: new Date().toLocaleDateString('en-US'),
      };
      updateHistory('shopping', updatedItem);
    }
    removeListItem(listType, item);
  };

  // Handle removing a list item from the item modal
  const handleRemoveListItemByModal = (item, qty) => {
    handleRemoveListItem(item, qty);
    closeItemModal();
  };

  // Handle removing a list item from the aisle modal
  const handleRemoveListItemByAisleModal = (item, qty) => {
    handleRemoveListItem(item, qty);
    closeAisleModal();
  };

  // Prepare store options for the select dropdown
  const storeOptions = filteredStores.map((store) => ({
    value: store.name,
    label: store.name,
  }));

  // Open the store modal
  const openStoreModal = () => {
    setStoreModalIsOpen(true);
  };

  // Handle changing the selected store
  const handleStoreChange = async (selectedOption) => {
    setSelectedStore(selectedOption);
    await updateUserPreffs({
      [`default_${listType.toLowerCase()}`]: selectedOption.value,
    });
    closeStoreModal();
  };

  // Close the store modal
  const closeStoreModal = () => {
    setStoreModalIsOpen(false);
  };

  // Open the item modal
  const openItemModal = (item) => {
    setSelectedItem(item);
    setFormData({
      name: item.name,
      origName: item.origName || item.name,
      qty: item.qty || 1,
      origQty: item.qty || 1,
      locations: item.locations,
      origLocations: item.origLocations || item.locations,
    });
    setItemModalIsOpen(true);
  };

  // Handle changing the item name
  const handleItemNameChange = (e) => {
    setFormData({ ...formData, name: e.target.value });
  };

  // Handle changing the item Aisle #
  const handleItemAisleChange = (e) => {
    // console.log('e', e.target.value);
    const storeExists = formData.locations.some(
      (location) => location.store === selectedStore?.value
    );

    if (storeExists) {
      // console.log('storeExists');
      // Update the aisle of the selectedStore in the formData
      const updatedLocations = formData.locations.map((location) =>
        location.store === selectedStore?.value
          ? { ...location, aisle: Number(e.target.value) }
          : location
      );
      setFormData({ ...formData, locations: updatedLocations });
    } else {
      // console.log('NOT storeExists');
      // Add the store and aisle to the formData
      const newLocation = {
        store: selectedStore?.value,
        aisle: Number(e.target.value),
      };
      // console.log('newLocation:', newLocation);
      const updatedLocations = [...formData.locations, newLocation];
      // console.log('updatedLocations:', updatedLocations);

      setFormData({
        ...formData,
        locations: updatedLocations,
      });
    }
  };

  // Handle submitting the item form
  const handleItemSubmit = async (e) => {
    e.preventDefault();
    const { name, origName, qty, origQty, locations, origLocations } = formData;
    // console.log(formData);

    // Check if the name or quantity has changed
    if (
      name.trim() !== origName ||
      qty !== origQty ||
      locations !== origLocations
    ) {
      // Update the list item in the Firestore database
      await changeListItem(listType, {
        ...formData,
        name: formData.name.trim(), // Trim name before updating
      });
    }

    // Check if the locations have changed
    if (locations !== origLocations) {
      // console.log('locations have changed');
      let updatedItem = getItemByName(origName);
      await updateItem({ ...updatedItem, locations: locations });
      // console.log('updatedItem:', { ...updatedItem, locations: locations });
    }

    setSelectedItem(null);
    setItemModalIsOpen(false);
  };

  // increment the item qty
  const incrementQty = () => {
    setSelectedItem({ ...selectedItem, qty: selectedItem.qty + 1 });
    setFormData({ ...formData, qty: formData.qty + 1 });
  };

  // decrement the item qty
  const decrementQty = () => {
    setSelectedItem({
      ...selectedItem,
      qty: selectedItem.qty > 1 ? selectedItem.qty - 1 : 1,
    });
    setFormData({ ...formData, qty: formData.qty > 1 ? formData.qty - 1 : 1 });
  };

  // Close the item modal
  const closeItemModal = () => {
    setItemModalIsOpen(false);
    setSelectedItem(null);
  };

  // Open the aisle modal
  const openAisleModal = (item) => {
    setSelectedItem(item);
    setFormData({
      name: item.name,
      origName: item.origName || item.name,
      locations: item.locations,
      origLocations: item.origLocations || item.locations,
    });
    setAisleModalIsOpen(true);
  };

  // Close the aisle modal
  const closeAisleModal = () => {
    setAisleModalIsOpen(false);
    setSelectedItem(null);
  };

  // Handle submitting the aisle form
  const handleAisleSubmit = async (e) => {
    e.preventDefault();
    const { origName, locations, origLocations } = formData;
    if (locations !== origLocations) {
      // The aisle changed
      let updatedItem = getItemByName(origName);
      await updateItem({ ...updatedItem, locations: locations });
    }
    await handleRemoveListItemByAisleModal(selectedItem.name, selectedItem.qty);
    setAisleModalIsOpen(false);
  };

  // Add this week's shopping list to the current list
  const addThisWeeksShoppingList = async () => {
    const mealsOnMenu = menus.find((menu) => menu.id === 'dinner');
    var newShoppingList = [];
    mealsOnMenu?.meals?.forEach((mealObj) => {
      if (
        mealObj &&
        mealObj.meal &&
        mealObj.slotNum < 8 &&
        mealObj.meal.shoppingList
      ) {
        newShoppingList = newShoppingList.concat(mealObj.meal.shoppingList);
      }
    });
    newShoppingList.length > 0 && openAddItemsModal(newShoppingList);
    // return bulkAddListItems(listType, newShoppingList);
  };

  // Add next week's shopping list to the current list
  const addNextWeeksShoppingList = async () => {
    const mealsOnMenu = menus.find((menu) => menu.id === 'dinner');
    var newShoppingList = [];
    mealsOnMenu.meals.forEach((mealObj) => {
      if (
        mealObj &&
        mealObj.meal &&
        mealObj.slotNum > 7 &&
        mealObj.meal.shoppingList
      ) {
        newShoppingList = newShoppingList.concat(mealObj.meal.shoppingList);
      }
    });
    newShoppingList.length > 0 && openAddItemsModal(newShoppingList);
    // return bulkAddListItems(listType, newShoppingList);
  };

  // Handle sort change
  const handleSort = async (columnKey) => {
    const order =
      sortOrder.columnKey === columnKey && sortOrder.order === 'ascend'
        ? 'descend'
        : 'ascend';
    setSortOrder({ columnKey, order });

    const updatedData = {
      storeName: selectedStore?.value,
      sortOrder: { columnKey, order },
    };
    await updateStoreSortOrder(updatedData);
  };

  // handle clear the list of all items
  const handleClearList = async () => {
    await removeListItem(listType, 'clear-all-list-items');
    setCurrentList([]);
    setClearListEnabled(false);
  };

  // Define columns for the table
  const col1 = {
    title: 'Aisle',
    dataIndex: 'aisle',
    width: '10%',
    align: 'center',
    sortOrder: sortOrder.columnKey === 'aisle' && sortOrder.order,
    onHeaderCell: (column) => ({
      onClick: () => handleSort('aisle'),
    }),
    sorter: (a, b) => a.aisle - b.aisle,
    render: (num) => (num < 0 ? '' : num),
  };

  const col2 = {
    title: 'Item Name',
    dataIndex: 'name',
    sortOrder: sortOrder.columnKey === 'name' && sortOrder.order,
    onHeaderCell: (column) => ({
      onClick: () => handleSort('name'),
    }),
    sorter: (a, b) => a.name.localeCompare(b.name),
  };

  const tableColumns = hasAisles ? [col1, col2] : [col2];

  // Prepare data for the table
  const tableData = currentList?.items?.map((item) => ({
    item: item,
    name: `${item.name} ${item.qty && item.qty > 1 ? '(' + item.qty + ')' : ''} `,
    aisle:
      item.locations.length > 0 &&
      item.locations.find((location) => location.store === selectedStore?.value)
        ? item.locations.find(
            (location) => location.store === selectedStore.value
          ).aisle
        : -1,
  }));

  if (!selectedStore) {
    return <div></div>; // or a loading indicator
  }

  return (
    <div className="mb-5 container-fluid">
      <div className="navbar shopper-navbar">
        <div className="nav-item p-0 ">
          <div
            onClick={openStoreModal}
            className={'store-picker fs-3 p-0 text-' + colorPref}
          >
            <ThreeDotsVertical size={20} className="pb-1" />
            {selectedStore.label}
          </div>
        </div>
        {clearListEnabled ? (
          <button
            type="button"
            className="btn btn-sm btn-danger nav-item"
            onClick={handleClearList}
          >
            Clear List
          </button>
        ) : (
          <div
            id="clear-list-button"
            className="nav-item p-0 pointer"
            onClick={() => setClearListEnabled(true)}
          >
            <Trash size={18} />
          </div>
        )}
      </div>

      {shoppingMode ? (
        <div></div>
      ) : (
        <div className="mb-3" id={'addItemPickerGroup-' + listType}>
          <InputGroup className="mb-1">
            <InputGroup.Text className="groceryPicker" style={{ width: '38%' }}>
              Add Single Item
            </InputGroup.Text>
            <Select
              className="flex-grow-top"
              id="addItemPicker"
              value={selectedItem}
              onChange={(picker) => handleAddListItem(picker.value)}
              options={sortedItems.map((item) => ({
                value: item,
                label: item.name,
              }))}
              placeholder="Select an item"
            />
          </InputGroup>
          {/* Conditionally render meal picker if listType is Grocery */}
          {(listType === 'Grocery' || listType === 'Wholesale') && (
            <InputGroup className="mb-1">
              <InputGroup.Text
                className="groceryPicker"
                style={{ width: '38%' }}
              >
                Add Meal Items
              </InputGroup.Text>
              <Select
                className="flex-grow-bottom"
                id="addMealPicker"
                value={selectedMeal}
                // onChange={(picker) => handleAddMealItems(picker.value)}
                onChange={(e) => openAddItemsModal(e.value.shoppingList)}
                options={sortedMeals.map((meal) => ({
                  value: meal,
                  label: meal.name,
                }))}
                placeholder="Select a meal"
              />
            </InputGroup>
          )}
          {/* Conditionally render menu buttons if listType is Grocery */}
          {(listType === 'Grocery' || listType === 'Wholesale') && (
            <ButtonGroup className="w-100 gap-1">
              <Button
                id="addThisWeeksShoppingList-button"
                variant="secondary"
                className="addMenu-button"
                onClick={addThisWeeksShoppingList}
              >
                Add <b>This Week's</b> Menu
              </Button>
              <Button
                id="addNextWeeksShoppingList-button"
                className="addMenu-button"
                variant={colorPref}
                onClick={addNextWeeksShoppingList}
              >
                Add <b>Next Week's</b> Menu
              </Button>
            </ButtonGroup>
          )}
        </div>
      )}

      <Table
        columns={tableColumns}
        dataSource={tableData}
        pagination={false}
        showSorterTooltip={false}
        rowKey={(r) => r.name}
        onRow={(r) => {
          return {
            onClick: () => handleSelectItem(r.item),
          };
        }}
      />

      {/* Store Picker Modal */}
      <Modal
        show={storeModalIsOpen}
        onHide={closeStoreModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'text-' + colorPref}>
            Select a {listType} Store
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Select
            className="mb-3"
            value={selectedStore}
            onChange={handleStoreChange}
            options={storeOptions}
            placeholder={`Select a ${listType.toLowerCase()} store`}
          />
        </Modal.Body>
      </Modal>

      {/* Item Edit Modal */}
      <Modal
        show={itemModalIsOpen}
        onHide={closeItemModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Form onSubmit={handleItemSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>Edit Item</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Control
              size="lg"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleItemNameChange}
              placeholder="Item Name"
              required
              autoComplete="off"
            />
            {/* Show original name (if applicable) */}
            {selectedItem?.origName ? (
              <div className="small text-muted text-center">
                <i>Original Name: {selectedItem.origName}</i>
              </div>
            ) : (
              <div className="small text-muted text-center">
                <i>Name changes will only show on the current list</i>
              </div>
            )}
            <div className="row mt-2">
              <div className="col-7 align-items-end flex-column d-flex">
                <div className="my-auto">
                  How Many? &nbsp; <ArrowRight size={20} />
                </div>
              </div>
              <div className="col-5 align-items-center d-flex">
                <InputGroup>
                  <Button size="sm" variant={colorPref} onClick={decrementQty}>
                    <Dash size={20} />
                  </Button>
                  <Form.Control
                    className="text-center"
                    size="sm"
                    type="number"
                    name="qty"
                    value={selectedItem?.qty || 1}
                    readOnly
                  />
                  <Button size="sm" variant={colorPref} onClick={incrementQty}>
                    <Plus size={20} />
                  </Button>
                </InputGroup>
              </div>
            </div>
            {/* Aisle Picker (if applicable) */}
            {stores.places.find((store) => store.name === selectedStore?.value)
              ?.aisles.length > 0 ? (
              <div className="mt-3 border-top">
                <Form.Label className="mt-2">
                  Aisle @ {selectedStore?.value}:
                </Form.Label>
                <InputGroup className="mb-1 ig-stores">
                  <Form.Select
                    name={selectedStore?.value}
                    value={
                      formData.locations.find(
                        (location) => location.store === selectedStore?.value
                      )?.aisle
                    }
                    onChange={handleItemAisleChange}
                  >
                    <option value=""></option>
                    {stores.places
                      .find((store) => store.name === selectedStore?.value)
                      .aisles.sort((a, b) => a.num - b.num)
                      .map((aisle, idx) => (
                        <option key={idx} value={aisle.num}>
                          {aisle.num + ' ' + aisle.name}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </div>
            ) : (
              <div className="mt-4 pt-3 text-muted text-center smaller border-top">
                <ExclamationCircle size={20} /> No aisles assigned to{' '}
                <b>{selectedStore?.value}</b>.<div className="mb-2"></div>
                <Link to="/stores">Add aisles to this store</Link> and then you
                can sort items on your list by aisle to make them easy to find.
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Save
              </Button>
              <Button
                variant={colorPref === 'danger' ? 'warning' : 'danger'}
                className="mr-2"
                onClick={() =>
                  handleRemoveListItemByModal(
                    selectedItem?.name,
                    selectedItem?.qty
                  )
                }
              >
                Remove from List
              </Button>
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={closeItemModal}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Aisle Picker Modal */}
      <Modal
        show={aisleModalIsOpen}
        onHide={closeAisleModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Form onSubmit={handleAisleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>Add Aisle</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="mb-3">
              <small>
                Hey <ShopperPetName />! Since you're adding{' '}
                <b className={'text-' + colorPref}>{selectedItem?.name}</b> to
                your cart, why not add the aisle number so it's easier to find
                next time.
              </small>
            </div>
            <Form.Label>Aisle @ {selectedStore?.value}:</Form.Label>
            <InputGroup className="mb-1 ig-stores">
              <Form.Select
                name={selectedStore?.value}
                value={
                  formData.locations.find(
                    (location) => location.store === selectedStore?.value
                  )?.aisle
                }
                onChange={handleItemAisleChange}
              >
                <option value="" disabled></option>
                {stores.places
                  .find((store) => store.name === selectedStore?.value)
                  .aisles.sort((a, b) => a.num - b.num)
                  .map((aisle) => (
                    <option key={aisle.name} value={aisle.num}>
                      {aisle.num + ' ' + aisle.name}
                    </option>
                  ))}
              </Form.Select>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Update
              </Button>
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={() =>
                  handleRemoveListItemByAisleModal(
                    selectedItem?.name,
                    selectedItem?.qty
                  )
                }
              >
                Not Now
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Bulk Add Items Modal */}
      <Modal
        show={addItemsModalIsOpen}
        onHide={closeAddItemsModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'text-' + colorPref}>
            Select Items to Add
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <p className="smaller border-bottom pb-3">
              Unselect any items you already have in stock. Duplicate items will
              be merged and summed before being added to the list.
            </p>
            {origItemsList?.map((item, index) => (
              <div key={index} className="form-check">
                <input
                  className="fs-4 form-check-input mt-1"
                  type="checkbox"
                  id={item.name + '-' + index}
                  value={item.name}
                  onClick={(e) => handleItemCheckbox(e.target.value)}
                  defaultChecked
                />
                <label
                  className={
                    'fs-5 form-check-label bg-' +
                    colorPref +
                    '-subtle mb-1 pb-1 px-2 rounded'
                  }
                  htmlFor={item.name + '-' + index}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-grid gap-2 d-flex justify-content-end">
            <Button
              variant={colorPref}
              className="mr-2"
              onClick={handleAddItemsByModal}
            >
              Add Items to Shopping List
            </Button>
            <Button
              variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
              onClick={closeAddItemsModal}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const ShopperPetName = () => {
  const names = [
    'Snack Hunter 😋',
    'Aisle Explorer 🤠',
    'Cart Captain 🤗',
    'Shelf Surfer 😎',
    'Produce Picker 🍍',
    'Bargain Boss 👶',
    'Grocery Guru 🤓',
    'Pantry Prowler 😸',
    'Checkout Champ 😇',
    'Fresh Finder 🤤',
  ];
  return names[Math.floor(Math.random() * names.length)];
};

export default ListManager;
