import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { XLg, Plus } from 'react-bootstrap-icons';

const MealRecipeTab = ({
  ingredients,
  setIngredients,
  instructions,
  setInstructions,
  colorPref,
  editMode,
}) => {
  const handleRemoveIngredient = (index) => {
    setIngredients(ingredients.filter((_, i) => i !== index));
  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, { name: '' }]);
  };

  const handleIngredientChange = (index, e) => {
    const ingredient = e.target.value;
    const newIngredientList = ingredients.map((item, i) =>
      i === index ? { ...item, name: ingredient } : item
    );
    setIngredients(newIngredientList);
  };

  const handleRemoveInstruction = (index) => {
    setInstructions(instructions.filter((_, i) => i !== index));
  };

  const handleAddInstruction = () => {
    setInstructions([...instructions, { name: '' }]);
  };

  const handleInstructionChange = (index, e) => {
    const instruction = e.target.value;
    const newInstructionList = instructions.map((item, i) =>
      i === index ? { ...item, name: instruction } : item
    );
    setInstructions(newInstructionList);
  };

  return (
    <div>
      {!editMode ? (
        <div>
          {ingredients.length === 0 && instructions.length === 0 && (
            <div>No recipe</div>
          )}
          {ingredients.length > 0 && (
            <>
              <ul>
                {ingredients?.map((item) => (
                  <li key={item.name}>{item.name}</li>
                ))}
              </ul>
            </>
          )}

          {instructions.length > 0 && (
            <>
              <p className="fs-6">Instructions:</p>
              <ol>
                {instructions?.map((item) => (
                  <li key={item.name}>{item.name}</li>
                ))}
              </ol>
            </>
          )}
        </div>
      ) : (
        <>
          <h6 className="pb-1">Ingredients</h6>
          {ingredients.length > 0 &&
            ingredients.map((item, index) => (
              <div key={index}>
                <InputGroup className="mb-1 flex-container">
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    className="flex-grow-1 meal-recipe-input"
                    placeholder="Add an ingredient"
                    value={item.name}
                    onBlur={(e) => e.preventDefault()}
                    onChange={(e) => {
                      handleIngredientChange(index, e);
                    }}
                  />
                  <Button
                    variant="outline-secondary"
                    className="meal-recipe-input-button"
                    onClick={() => handleRemoveIngredient(index)}
                  >
                    <XLg size={12} />
                  </Button>
                </InputGroup>
              </div>
            ))}
          <div className="d-grid gap-2">
            <Button size="sm" variant={colorPref} onClick={handleAddIngredient}>
              <Plus size={20} />
              Add Ingredient to Recipe
            </Button>
          </div>

          <h6 className="mt-4 pb-1">Step-by-Step Instructions</h6>
          {instructions.length > 0 &&
            instructions.map((item, index) => (
              <div key={index}>
                <InputGroup className="mb-1 flex-container">
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    className="flex-grow-1 meal-recipe-input"
                    placeholder="Add an instruction"
                    value={item.name}
                    onBlur={(e) => e.preventDefault()}
                    onChange={(e) => {
                      handleInstructionChange(index, e);
                    }}
                  />
                  <Button
                    variant="outline-secondary"
                    className="meal-recipe-input-button"
                    onClick={() => handleRemoveInstruction(index)}
                  >
                    <XLg size={12} />
                  </Button>
                </InputGroup>
              </div>
            ))}
          <div className="d-grid gap-2">
            <Button
              size="sm"
              variant={colorPref}
              onClick={handleAddInstruction}
            >
              <Plus size={20} />
              Add Instruction to Recipe
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default MealRecipeTab;
