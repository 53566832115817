import { useState, useContext } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import {
  List,
  Sliders,
  RewindBtn,
  ArrowClockwise,
  BoxArrowRight,
  EggFried,
  MenuApp,
  Cart2,
  Boxes,
  ShopWindow,
  ArrowRight,
} from 'react-bootstrap-icons';
import { DataContext } from './DataProvider';

const Layout = () => {
  const navigate = useNavigate();
  const { colorPref, logout, versionMatch } = useContext(DataContext);

  const [showOffCanvasMenu, setOffCanvasMenu] = useState(false);
  const handleOffCanvasMenuClose = () => setOffCanvasMenu(false);
  const handleOffCanvasMenuShow = () => setOffCanvasMenu(true);

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  return (
    <>
      <Navbar id="navbar-shop" fixed="top" bg={colorPref} data-bs-theme="dark">
        <Container className="text-white">
          <div className="shopping-mode-text">
            Shopping Mode: <b>Enabled</b>&nbsp; &nbsp; &bull; &nbsp; &nbsp;
            Shopping History: <b>On</b>
          </div>
        </Container>
      </Navbar>
      <Navbar id="navbar-top" fixed="top" bg={colorPref} data-bs-theme="dark">
        <Container>
          <Nav className="w-100">
            <Nav.Item>
              <Nav.Link className="navbar-brand">
                <List size={24} onClick={handleOffCanvasMenuShow} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="m-auto">
              <Nav.Link className="navbar-brand">
                <img
                  alt="Meal Buddy"
                  className="me-2 navbar-2"
                  src="/buddy-logo.png"
                  width={28}
                />
                Meal Buddy&trade;{' '}
                {process.env.NODE_ENV === 'development'
                  ? process.env.REACT_APP_FIREBASE_ENV.toUpperCase()
                  : process.env.REACT_APP_FIREBASE_ENV !== 'prod' &&
                    process.env.REACT_APP_FIREBASE_ENV.toUpperCase() + '!'}
              </Nav.Link>
            </Nav.Item>
            <Nav.Link className="navbar-brand">
              <Sliders size={20} onClick={handleSettingsClick} />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      {!versionMatch && (
        <div
          className="pointer mt-4 w-100 text-center"
          onClick={() => window.location.reload(true)}
        >
          <span className={'badge rounded-pill text-bg-' + colorPref}>
            <ArrowClockwise /> New version available. Tap to reload{' '}
            <ArrowClockwise />
          </span>
        </div>
      )}
      <Outlet />
      <Navbar
        id="navbar-bottom"
        fixed="bottom"
        bg={colorPref}
        data-bs-theme="dark"
      >
        <Container className="navColor">
          <Navbar.Brand as={Link} to="/">
            <div className="text-center">
              <MenuApp size={20} />
            </div>
            <div
              className={
                window.location.pathname === '/'
                  ? 'menu-text menu-active'
                  : 'menu-text'
              }
            >
              Menu
            </div>
          </Navbar.Brand>
          <Navbar.Brand as={Link} to="/meals">
            <div className="text-center">
              <EggFried size={20} />
            </div>
            <div
              className={
                window.location.pathname === '/meals'
                  ? 'menu-text menu-active'
                  : 'menu-text'
              }
            >
              Meals
            </div>
          </Navbar.Brand>
          <Navbar.Brand as={Link} to="/items">
            <div className="text-center">
              <Boxes size={20} />
            </div>
            <div
              className={
                window.location.pathname === '/items'
                  ? 'menu-text menu-active'
                  : 'menu-text'
              }
            >
              Items
            </div>
          </Navbar.Brand>
          <Navbar.Brand as={Link} to="/shop">
            <div className="text-center">
              <Cart2 size={20} />
            </div>
            <div
              className={
                window.location.pathname === '/shop'
                  ? 'menu-text menu-active'
                  : 'menu-text'
              }
            >
              Shop
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Offcanvas
        className="offcanvas-main"
        show={showOffCanvasMenu}
        onHide={handleOffCanvasMenuClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-option">
            <Link to="/stores" onClick={handleOffCanvasMenuClose}>
              <ShopWindow size={21} className="me-2 mb-1" />
              Stores
            </Link>
          </div>
          <div className="offcanvas-option">
            <Link to="/history" onClick={handleOffCanvasMenuClose}>
              <RewindBtn size={21} className="me-2 mb-1" />
              History
            </Link>
          </div>
          <hr />
          <div className="offcanvas-option">
            <Link onClick={() => window.location.reload(true)}>
              <ArrowClockwise size={21} className="me-2 mb-1" />
              Reload
            </Link>
          </div>
          <div className="offcanvas-option">
            <Link onClick={() => logout('/')}>
              <BoxArrowRight size={21} className="me-2 mb-1" />
              Logout
            </Link>
          </div>
          <div className="about-div">
            <hr />
            <p className="text-muted about-text">
              <b className={'text-' + colorPref}>
                Meal Buddy v{global.config.version}
              </b>
              <br />
              Created by {global.config.author}
              <br />
              {global.config.devDate}
            </p>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Layout;
