import { useState, useEffect } from 'react';
// import { DataContext } from '../DataProvider.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Magic, Trash } from 'react-bootstrap-icons';
import { Tabs } from 'antd';
import ShoppingListTab from './ShoppingListTab';
import RecipeTab from './RecipeTab';
import NotesTab from './NotesTab';
import AiTab from './AiTab';

const MealForm = ({
  formData,
  closeModal,
  colorPref,
  modalIsOpen,
  items,
  updateMeal,
  addMeal,
  deleteMeal,
  fetchMealByUrl,
  isDuplicateMeal,
  createItem,
  currentMeal,
}) => {
  // console.log('formData:', JSON.stringify(formData));
  const [editMode, setEditMode] = useState(false);
  const [deletable, setDeletable] = useState(false);
  const [name, setName] = useState(formData?.name || '');
  const [sides, setSides] = useState(formData?.sides || []);
  const [notes, setNotes] = useState(formData?.notes || '');
  const [ingredients, setIngredients] = useState(
    formData?.ingredientsList || []
  );
  const [instructions, setInstructions] = useState(
    formData?.instructions || []
  );
  const [shoppingList, setShoppingList] = useState(
    formData?.shoppingList || []
  );
  const [sourceUrl, setSourceUrl] = useState(formData?.sourceUrl || '');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setName(formData?.name || '');
    setSides(formData?.sides || []);
    setNotes(formData?.notes || '');
    setIngredients(formData?.ingredientsList || []);
    setInstructions(formData?.instructionsList || []);
    setShoppingList(formData?.shoppingList || []);
    setSourceUrl(formData?.sourceUrl || '');
    setEditMode(false);
    setDeletable(false);
    !currentMeal && setEditMode(true);
    setShowError(false);
    setErrorMessage('');
  }, [formData, currentMeal]);

  const handleSubmit = async () => {
    setShowError(false);
    setErrorMessage('');

    if (!name) {
      setErrorMessage('Error adding meal: Name is required');
      setShowError(true);
      return;
    }

    // skip the quick dupe check when updating
    if (isDuplicateMeal(name) && !formData?.id) {
      setErrorMessage('Error adding meal: Name already exists');
      setShowError(true);
      return;
    }

    // get values for recipe and notes...
    const ingredientsList = ingredients.map((item) => item.name);
    const instructionsList = instructions.map((item) => item.name);
    const mealData = {
      name,
      sides,
      notes,
      ingredientsList,
      instructionsList,
      shoppingList,
      sourceUrl,
    };
    if (formData?.id) {
      const updateData = { ...mealData, id: formData.id };
      // console.log('updateData:', updateData);
      const { success, message } = await updateMeal(updateData);
      if (success) {
        closeModal();
      } else {
        setErrorMessage(message);
        setShowError(true);
      }
    } else {
      // console.log('docData:', docData);
      // iterate through the shoppingList and add items not already in the items collection
      shoppingList.forEach((groceryItem) => {
        // do a case insensitive match and add a new item only if not match.
        // if they match then update shoppingList with the original item in its original case
        const groceryItemLower = groceryItem.name.toLowerCase();
        const existingItem = items.find(
          (item) => item.name.toLowerCase() === groceryItemLower
        );
        if (existingItem) {
          shoppingList[shoppingList.indexOf(groceryItem)] = existingItem;
        } else {
          // add new item to the items collection
          createItem({ name: groceryItem.name, locations: [] });
        }
      });

      const { success, message } = await addMeal(mealData);
      if (success) {
        closeModal();
      } else {
        setErrorMessage(message);
        setShowError(true);
      }
    }
  };

  const handleRemove = async () => {
    await deleteMeal(formData.id);
    closeModal();
  };

  const tabItems = [
    {
      key: '1',
      label: 'Recipe',
      children: (
        <RecipeTab
          ingredients={ingredients}
          setIngredients={setIngredients}
          instructions={instructions}
          setInstructions={setInstructions}
          colorPref={colorPref}
          editMode={editMode}
        />
      ),
    },
    {
      key: '2',
      label: 'Notes',
      children: (
        <NotesTab notes={notes} setNotes={setNotes} editMode={editMode} />
      ),
    },
    {
      key: '3',
      label: 'Grocery List',
      children: (
        <ShoppingListTab
          mealName={name}
          items={items}
          createItem={createItem}
          shoppingList={shoppingList}
          setShoppingList={setShoppingList}
          colorPref={colorPref}
          editMode={editMode}
        />
      ),
    },
  ];

  // show the magic tab only when adding a meal OR when the source URL is pesent
  (!currentMeal || formData?.sourceUrl) &&
    tabItems.push({
      key: '4',
      label: <Magic size={16} />,
      children: (
        <AiTab
          fetchMealByUrl={fetchMealByUrl}
          setName={setName}
          setShoppingList={setShoppingList}
          setSides={setSides}
          setNotes={setNotes}
          setIngredients={setIngredients}
          setInstructions={setInstructions}
          initialSourceUrl={formData?.sourceUrl ? true : false}
          sourceUrl={sourceUrl}
          setSourceUrl={setSourceUrl}
          colorPref={colorPref}
        />
      ),
    });

  return (
    <Modal
      show={modalIsOpen}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className={'text-' + colorPref}>
          {currentMeal && editMode
            ? 'Edit Meal'
            : currentMeal
              ? 'View Meal'
              : 'Add Meal'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editMode ? (
          <>
            <div className="mb-2">
              <Form.Control
                size="lg"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
              />
              {showError && <div className="text-danger">{errorMessage}</div>}
            </div>
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="Sides"
                value={sides?.join(', ')}
                onChange={(e) => setSides(e.target.value.split(', '))}
                autoComplete="off"
              />
            </div>
          </>
        ) : (
          <div className="mb-3">
            <p className="fs-1 mb-0">{name}</p>
            {sides.length > 0 && <div>w/{sides.join(', ')}</div>}
          </div>
        )}

        <Tabs defaultActiveKey="1" items={tabItems} type="card" />
      </Modal.Body>
      <Modal.Footer>
        {!editMode ? (
          <Button variant={colorPref} onClick={() => setEditMode(true)}>
            Edit
          </Button>
        ) : (
          <Button variant={colorPref} onClick={handleSubmit}>
            Save
          </Button>
        )}

        {currentMeal && deletable && (
          <Button
            variant={colorPref === 'danger' ? 'warning' : 'danger'}
            className="mr-2"
            onClick={handleRemove}
          >
            Delete this Meal
          </Button>
        )}

        {currentMeal && !deletable && (
          <Button
            variant={colorPref}
            className="mr-2"
            onClick={() => setDeletable(true)}
          >
            <Trash size={16} />
          </Button>
        )}

        <Button
          variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MealForm;
