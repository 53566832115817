import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, model, analytics, auth } from '../firebase.config';
import { signOut } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import {
  collection,
  getDocs,
  getDoc,
  onSnapshot,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore';

// Create a context to share data and functions across components
const DataContext = createContext();

const DataProvider = ({ children, currentUser, currentAccount }) => {
  // State variables to store data from Firestore
  const [loading, setLoading] = useState(true); // Flag to indicate if data is being loaded
  const [menus, setMenus] = useState([]); // Array to store menu data
  const [meals, setMeals] = useState([]); // Array to store meal data
  const [items, setItems] = useState([]); // Array to store item data
  const [lists, setLists] = useState([]); // Array to store various list data (e.g., Grocery, Settings)
  const [history, setHistory] = useState([]); // Array to store meal history
  const [userPreffs, setUserPreffs] = useState({}); // Object to store user preferences
  const [acctPrefs, setAcctPrefs] = useState({}); // Object to store account preferences
  const [colorPref, setColorPref] = useState('dark');
  const [versionMatch, setVersionMatch] = useState(true);

  // log events to Firebase Analytics (GA4)
  const firebaseAnalytics = (event, data) => {
    // console.log('event logger...', event, data);
    // Log event
    logEvent(analytics, event, data);
    return null;
  };

  // --------------------------------------------------------------------------
  // MENU
  // --------------------------------------------------------------------------

  // Function to add a new menuWeek list in Firestore
  const addNewMenu = (startDay) => {
    updateMenuPrefs(startDay, getStartDayDate(startDay));
  };

  const updateMenuPrefs = async (startDay, startDayDate) => {
    // Define the days of the week in order
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    // Find the index of the starting day
    const startIndex = daysOfWeek.indexOf(startDay);

    // Generate the ordered list of days starting from the given day
    const orderedDays = [];
    for (let i = 0; i < daysOfWeek.length; i++) {
      orderedDays.push(daysOfWeek[(startIndex + i) % daysOfWeek.length]);
    }
    return await updateAccountPrefs({
      menuWeek: orderedDays,
      menuStart: startDayDate,
    });
  };

  const updateMenuStartDate = async (startDay) => {
    return await updateAccountPrefs({
      menuStart: getStartDayDate(startDay),
    });
  };

  // Function to update the menu document in Firestore
  const updateMenu = async (data) => {
    try {
      const menuDoc = doc(db, 'accounts', currentAccount, 'menus', 'dinner');
      await updateDoc(menuDoc, { meals: data });
      firebaseAnalytics('add_meal_to_menu', { msg: 'Update Menu' });
    } catch (error) {
      console.error('Error updating menu: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateMenu',
      });
    }
  };

  const getStartDayDate = (weekday) => {
    // List of days in order starting from Sunday
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    // Get current date and day
    const today = new Date();
    const currentDay = today.getDay(); // Get current day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

    // Format the date as "mm/dd/yyyy"
    const formatDate = (date) => {
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth is zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };

    // Find the index of the start day
    const startDayIndex = daysOfWeek.indexOf(weekday.toLowerCase());

    if (startDayIndex === -1) {
      throw new Error('Invalid start day');
    }

    // If the start day is the same as today, return today's date
    if (currentDay === startDayIndex) {
      return formatDate(today);
    }

    // Calculate the difference in days (for past)
    // if the result is 0, set it to 7 to go to the last occurrence
    const dayDifference = (currentDay - startDayIndex + 7) % 7 || 7;

    // Get the date of the last start day by subtracting the difference
    const startDayDate = new Date(today);
    startDayDate.setDate(today.getDate() - dayDifference);

    return formatDate(startDayDate);
  };

  // --------------------------------------------------------------------------
  // MEALS
  // --------------------------------------------------------------------------

  // Function to add a new meal to the 'meals' collection in Firestore
  const addMeal = async (data) => {
    // Remove any empty items from the shopping list before adding the meal
    const cleanedData = {
      ...data,
      shoppingList: data.shoppingList.filter((item) => item.name),
    };

    // Check if a meal with the same name already exists
    const existingMealNames = meals.map((meal) => meal.name.toLowerCase());
    if (existingMealNames.includes(cleanedData.name.toLowerCase())) {
      return {
        success: false,
        message: 'Error adding meal: Name already exists.',
      };
    }

    try {
      // Add the new meal to the 'meals' collection
      await addDoc(
        collection(db, 'accounts', currentAccount, 'meals'),
        cleanedData
      );
      firebaseAnalytics('add_meal', { msg: 'Add Meal' });
      return { success: true, message: 'Meal added successfully.' };
    } catch (error) {
      console.error('Error adding meal: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'addMeal',
      });
      return { success: false, message: 'Error adding item: ' + error };
    }
  };

  const fetchMealByUrl = async (url) => {
    const tempScale = 'fahrenheit'; // celsius
    const systemType = 'imperial'; // metric
    const jsonFormat = `{
      "meal": {
        "name": "Sample Meal Name",
        "ingredients": ["1 pound ingredient 1", "1 1/2 teaspoons ingredient 2"],
        "instructions": ["Preheat oven...", "Step 2"],
        "servings": "4",
        "groceries": ["ingredient 1", "ingredient 2"],
        "source_url": "URL"
      }
    }`;

    // Construct the prompt using template literals
    const prompt = `Please retrieve the recipe from this URL: ${url}. 
    Use ${tempScale} as the scale for temperature. 
    Use the ${systemType} system for weights and measures. 
    Return it in this JSON format: ${jsonFormat}
    If you get an error, cannot find a recipe, or do not have enough information 
    to create a meal, then set the value of the meal name to FAILED and 
    add a new field named "message" in the JSON with the reason why.`;
    // console.log(prompt);

    try {
      const result = await model.generateContent(prompt);
      const response = result.response;
      const parsedUrl = new URL(url);
      firebaseAnalytics('fetch_meal_by_url', {
        domain: parsedUrl.hostname,
        path: parsedUrl.pathname,
      });
      return response.text();
    } catch (error) {
      console.error('Error fetching meal by URL: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'fetchMealByUrl',
      });
      return (
        '{ "meal": { "name": "FAILED", "message": "Error fetching meal by URL: ' +
        error +
        '" } }'
      );
    }
  };

  // Function to update an existing meal in the 'meals' collection in Firestore
  const updateMeal = async (data) => {
    // Remove any empty items from the shopping list before updating the meal
    const cleanedData = {
      ...data,
      shoppingList: data.shoppingList.filter((item) => item.name),
    };

    // Check if a meal with the same name already exists (excluding the meal being updated)
    const existingMealNames = meals.map(
      (meal) => meal.id !== cleanedData.id && meal.name.toLowerCase()
    );
    if (existingMealNames.includes(cleanedData.name.toLowerCase())) {
      return {
        success: false,
        message: 'Error adding meal: Name already exists.',
      };
    }

    // Extract the meal ID and the rest of the meal data
    const { id, ...rest } = cleanedData;

    try {
      // Update the meal in the 'meals' collection
      const mealDoc = doc(db, 'accounts', currentAccount, 'meals', id);
      await updateDoc(mealDoc, rest);
      firebaseAnalytics('update_meal', { msg: 'Update Meal' });
      return { success: true, message: 'Meal updated successfully.' };
    } catch (error) {
      console.error('Error updating meal: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateMeal',
      });
      return { success: false, message: 'Error updating meal: ' + error };
    }
  };

  // Function to delete a meal from the 'meals' collection in Firestore
  const deleteMeal = async (id) => {
    try {
      // Delete the meal from the 'meals' collection
      await deleteDoc(doc(db, 'accounts', currentAccount, 'meals', id));
      firebaseAnalytics('remove_meal', { msg: 'Delete Meal' });
    } catch (error) {
      console.error('Error deleting meal: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'deleteMeal',
      });
    }
  };

  // Function to find all meals that contain a specific item in their shopping list
  const findMealsContainingItem = (itemName) => {
    const mealsWithItem = meals.filter((meal) =>
      meal.shoppingList.some((item) => item.name === itemName)
    );
    return mealsWithItem;
  };

  // --------------------------------------------------------------------------
  // ITEMS
  // --------------------------------------------------------------------------

  // Function to add a new item to the 'items' collection in Firestore
  const addItem = async (data) => {
    // Check if an item with the same name already exists
    const existingItemNames = items.map((item) => item.name.toLowerCase());
    if (existingItemNames.includes(data.name.toLowerCase())) {
      return {
        success: false,
        message: 'Error adding item: Name already exists.',
      };
    }

    try {
      // Add the new item to the 'items' collection
      await addDoc(collection(db, 'accounts', currentAccount, 'items'), data);
      firebaseAnalytics('add_item', { msg: 'Add Item' });
      return { success: true, message: 'Item added successfully.' };
    } catch (error) {
      console.error('Error adding item: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'addItem',
      });
      return { success: false, message: 'Error adding item: ' + error };
    }
  };

  // Function to add new item with no duplicate checking
  const createItem = async (data) => {
    if (data.name === '') {
      return;
    }

    try {
      // Add the new item to the 'items' collection
      await addDoc(collection(db, 'accounts', currentAccount, 'items'), data);
    } catch (error) {
      console.error('Error adding item: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'createItem',
      });
    }
  };

  // Function to update an existing item in the 'items' collection in Firestore
  const updateItem = async (data) => {
    // Get the original item name in case it was changed
    const originalItemName = items.find((item) => item.id === data.id)?.name;

    // Check if an item with the same name already exists (excluding the item being updated)
    const existingItemNames = items.map(
      (item) => item.id !== data.id && item.name?.toLowerCase()
    );
    if (existingItemNames?.includes(data.name?.toLowerCase())) {
      return {
        success: false,
        message: 'Error adding item: Name already exists.',
      };
    }

    try {
      // Update the item in the 'items' collection
      const { id, ...rest } = data;
      const itemDoc = doc(db, 'accounts', currentAccount, 'items', id);
      await updateDoc(itemDoc, rest);
      firebaseAnalytics('update_item', { msg: 'Update Item' });
    } catch (error) {
      console.error('Error updating item: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateItem',
      });
      return { success: false, message: 'Error adding item: ' + error };
    }

    // Update the item name in all meals where it's referenced in the shopping list
    const mealsWithItem = findMealsContainingItem(originalItemName);
    mealsWithItem.forEach((meal) => {
      updateItemInMeal(meal.id, originalItemName, data);
    });

    return { success: true, message: 'Item added successfully.' };
  };

  // Function to update an item in a meal's shopping list
  const updateItemInMeal = async (
    mealId,
    originalItemName,
    updatedItemData
  ) => {
    // Extract updated item data (excluding the ID)
    const { id, ...rest } = updatedItemData;

    // Find the meal to update
    const meal = meals.find((meal) => meal.id === mealId);

    // Update the shopping list of the meal by replacing the old item with the updated one
    const updatedShoppingList = meal.shoppingList.map((item) => {
      if (item.name === originalItemName) {
        return { ...item, ...rest };
      }
      return item;
    });

    // Update the meal with the new shopping list
    const updatedMeal = { ...meal, shoppingList: updatedShoppingList };
    updateMeal(updatedMeal);
  };

  // Function to get an item from the 'items' state by its name
  const getItemByName = (name) => {
    const item = items.find((item) => item.name === name);
    return item ? item : null;
  };

  // Function to delete an item from the 'items' collection in Firestore
  const deleteItem = async (data) => {
    try {
      // Delete the item from the 'items' collection
      await deleteDoc(doc(db, 'accounts', currentAccount, 'items', data.id));
      firebaseAnalytics('remove_item', { msg: 'Delete Item' });
    } catch (error) {
      console.error('Error deleting item: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'deleteItem',
      });
    }
  };

  // Function to find all items that have a specific store name in their locations
  const findItemsContainingStore = (storeName) => {
    const itemsWithStore = items.filter((item) =>
      item.locations.some((location) => location.store === storeName)
    );
    return itemsWithStore;
  };

  // Function to update the store name in an item's locations array
  const updateStoreInItem = async (
    itemId,
    originalStoreName,
    updatedStoreName
  ) => {
    // Find the item to update
    const item = items.find((item) => item.id === itemId);

    // Update the item's locations array by replacing the old store name with the new one
    const updatedLocations = item.locations.map((location) => {
      if (location.store === originalStoreName) {
        return { ...location, store: updatedStoreName };
      }
      return location;
    });

    // Update the item with the new locations array
    const updatedItem = { ...item, locations: updatedLocations };
    updateItem(updatedItem);
  };

  // --------------------------------------------------------------------------
  // SHOPPING LISTS
  // --------------------------------------------------------------------------

  // Function to add a new item to a specified list type in Firestore
  const addListItem = async (listType, newItem) => {
    // Extract item data (excluding the ID)
    const { id, ...rest } = newItem;

    // Find the target list based on the listType parameter
    const targetList = lists.find((list) => list.id === listType);

    // Find existing item with the same name
    const existingItem = targetList.items.find(
      (item) => item.name === newItem.name || item.origName === newItem.name
    );

    // If item exists, increment its quantity
    if (existingItem) {
      const updatedItem = {
        ...existingItem,
        qty: (existingItem.qty || 1) + 1,
      };
      updateListItem(listType, updatedItem.name, updatedItem);
    } else {
      // If item doesn't exist, add it with a quantity of 1
      const updatedList = [...targetList.items, { ...rest, qty: 1 }];
      const listDocRef = doc(db, 'accounts', currentAccount, 'lists', listType);
      try {
        await updateDoc(listDocRef, { items: updatedList });
        firebaseAnalytics('add_item_to_list', {
          msg: 'Add Item to List',
          listType: listType,
        });
      } catch (error) {
        console.error(`Error adding item to ${listType}: `, error);
        firebaseAnalytics('exception', {
          description: error.code,
          fatal: true,
          functionName: 'addListItem',
        });
      }
    }
  };

  // Function to update an item in a specified list type in Firestore
  const changeListItem = async (listType, data) => {
    // Find the target list based on the listType parameter
    const targetList = lists.find((list) => list.id === listType);
    const listDocRef = doc(db, 'accounts', currentAccount, 'lists', listType);

    // Update the list by finding the item with the original name and updating its properties
    const updatedList = targetList.items.map((item) => {
      if (item.origName === data.origName || item.name === data.origName) {
        return {
          ...item,
          name: data.name,
          qty: data.qty,
          ...(data.origName !== data.name && { origName: data.origName }),
          locations: data.locations,
        };
      } else {
        return item;
      }
    });

    try {
      await updateDoc(listDocRef, { items: updatedList });
      firebaseAnalytics('change_item_in_list', {
        msg: 'Change Item in List',
        listType: listType,
      });
    } catch (error) {
      console.error(`Error updating ${listType} item: `, error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'changeListItem',
      });
    }
  };

  const updateListItem = async (listType, name, updatedItem) => {
    // Find the target list based on the listType parameter
    const targetList = lists.find((list) => list.id === listType);
    const listDocRef = doc(db, 'accounts', currentAccount, 'lists', listType);

    // Update the list by finding the item with the specified name and updating its properties
    const updatedList = targetList.items.map((item) => {
      if (item.name === name) {
        return updatedItem;
      } else {
        return item;
      }
    });

    try {
      await updateDoc(listDocRef, { items: updatedList });
      firebaseAnalytics('update_item_in_list', {
        msg: 'Update Item in List',
        listType: listType,
      });
    } catch (error) {
      console.error(`Error updating ${listType} item: `, error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateListItem',
      });
    }
  };

  // Function to add multiple items to a specified list type in Firestore, preventing duplicates
  const bulkAddListItems = async (listType, data) => {
    // Find the target list based on the listType parameter
    const targetList = lists.find((list) => list.id === listType);
    const listDocRef = doc(db, 'accounts', currentAccount, 'lists', listType);

    // Create a map to consolidate the incoming items and handle duplicates
    const consolidatedItems = data.reduce((acc, item) => {
      const existingItem = acc.find(
        (i) => i.name === item.name || i.origName === item.name
      );

      if (existingItem) {
        // If item already exists in the map, increment its quantity
        existingItem.qty = (existingItem.qty || 1) + 1;
      } else {
        // If item is new, add it to the map with a quantity of 1
        acc.push({ ...item, qty: 1 });
      }
      return acc;
    }, []);

    // Loop through each consolidated item and merge it with the target list
    const updatedList = [...targetList.items];
    consolidatedItems.forEach((incomingItem) => {
      const existingItem = updatedList.find(
        (item) =>
          item.name === incomingItem.name || item.origName === incomingItem.name
      );

      if (existingItem) {
        // If item exists in the target list, increment its quantity
        existingItem.qty = (existingItem.qty || 1) + incomingItem.qty;
      } else {
        // If item doesn't exist, add it to the list
        updatedList.push({ ...incomingItem });
      }
    });

    try {
      // Update Firestore with the final list of non-duplicate items
      await updateDoc(listDocRef, { items: updatedList });
      firebaseAnalytics('bulk_add_items_to_list', {
        msg: 'Bulk Add Items to List',
        listType: listType,
      });
    } catch (error) {
      console.error(`Error adding bulk items to ${listType}: `, error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'bulkAddListItems',
      });
    }
  };

  // Function to remove an item from a specified list type in Firestore
  const removeListItem = async (listType, name) => {
    // Find the target list based on the listType parameter
    const targetList = lists.find((list) => list.id === listType);
    const listDocRef = doc(db, 'accounts', currentAccount, 'lists', listType);
    var updatedList = [];

    if (name !== 'clear-all-list-items') {
      // Update the target list by removing the item with the specified name
      updatedList = targetList.items.filter((item) => item.name !== name);
    }
    try {
      await updateDoc(listDocRef, { items: updatedList });
      firebaseAnalytics('remove_item_from_list', {
        msg: 'Remove Item from List',
        listType: listType,
      });
    } catch (error) {
      console.error(`Error removing item from ${listType}: `, error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'removeListItem',
      });
    }
  };

  // --------------------------------------------------------------------------
  // STORES
  // --------------------------------------------------------------------------

  const stores = lists.find((list) => list.id === 'Stores');
  const storeTypes = [...new Set(stores?.places?.map((store) => store.type))];

  // Function to add a new store to the 'Stores' list in Firestore
  const addStore = async (newStore) => {
    // Find the 'Stores' list
    const stores = lists.find((list) => list.id === 'Stores');

    // Check if a store with the same name already exists
    const isDuplicate = stores.places.some(
      (store) => store.name === newStore.name
    );
    if (isDuplicate) {
      console.log(`Store ${newStore.name} already exists.`);
      return;
    }

    // Update the 'Stores' list with the new store
    const storesDoc = doc(db, 'accounts', currentAccount, 'lists', 'Stores');
    const updatedStores = [...stores.places, newStore];
    try {
      await updateDoc(storesDoc, { places: updatedStores });
      // Ensure that a corresponding store type list exists
      await createOrVerifyStoreTypeList(newStore.type, newStore.name);
      firebaseAnalytics('add_store', {
        msg: 'Add Store',
        storeType: newStore.type,
      });
    } catch (error) {
      console.error('Error adding store: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'addStore',
      });
    }
  };

  // Helper function to create a new list for a store type if it doesn't already exist
  async function createOrVerifyStoreTypeList(storeType, storeName) {
    // Check if a list with the store type as ID already exists
    const isTypeDuplicate = lists.find((list) => list.id === storeType);
    if (!isTypeDuplicate) {
      // If the store type list doesn't exist, create a new document for it
      const newStoreType = {
        items: [],
      };
      // Use setDoc to specifically set the document ID to the storeType
      await setDoc(
        doc(db, 'accounts', currentAccount, 'lists', storeType),
        newStoreType
      );
    }
  }

  // Function to update an existing store in the 'Stores' list in Firestore
  const updateStore = async (originalStoreName, data) => {
    console.log('data:', data);
    // Remove any empty aisles from the store data
    data.aisles = data.aisles.filter((aisle) => aisle.name !== '');

    // Find the 'Stores' list
    const stores = lists.find((list) => list.id === 'Stores');
    const storesDoc = doc(db, 'accounts', currentAccount, 'lists', 'Stores');

    // Update the 'Stores' list by finding the store with the original name and updating its data
    const updatedStores = stores.places.map((store) => {
      if (store.name === originalStoreName) {
        return { ...store, ...data };
      }
      return store;
    });

    try {
      await updateDoc(storesDoc, { places: updatedStores });
      firebaseAnalytics('update_store', { msg: 'Update Store' });
    } catch (error) {
      console.error('Error updating store: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateStore',
      });
    }

    // Update the store name in all items' locations where it's referenced
    const itemsWithStore = findItemsContainingStore(originalStoreName);
    itemsWithStore.forEach((item) => {
      updateStoreInItem(item.id, originalStoreName, data.name);
    });
  };

  // Function to remove a store from the 'Stores' list in Firestore
  const removeStore = async (name) => {
    // Find the 'Stores' list
    const stores = lists.find((list) => list.id === 'Stores');
    const storesDoc = doc(db, 'accounts', currentAccount, 'lists', 'Stores');

    // Update the 'Stores' list by removing the store with the specified name
    const updatedStores = stores.places.filter((store) => store.name !== name);
    try {
      await updateDoc(storesDoc, { places: updatedStores });
      firebaseAnalytics('remove_store', { msg: 'Remove Store' });
    } catch (error) {
      console.error('Error adding store: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'removeStore',
      });
    }
  };

  // Function to update the sort order for a specific store in the 'Stores' list
  const updateStoreSortOrder = async (data) => {
    const storesDoc = doc(db, 'accounts', currentAccount, 'lists', 'Stores');

    // Update the 'Stores' list by finding the store and updating its sort order
    const updatedStores = stores?.places?.map((store) => {
      if (store.name === data.storeName) {
        return {
          ...store,
          sortOrder: {
            ...data.sortOrder,
          },
        };
      }
      return store;
    });

    try {
      await updateDoc(storesDoc, { places: updatedStores });
    } catch (error) {
      console.error('Error updating settings: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateStoreSortOrder',
      });
    }
  };

  // --------------------------------------------------------------------------
  // SETTINGS / PREFERENCES / VERSION / LOGOUT
  // --------------------------------------------------------------------------

  // Function to update the 'Settings' document in Firestore
  const updateSettings = async (data) => {
    const settingsDoc = doc(
      db,
      'accounts',
      currentAccount,
      'lists',
      'Settings'
    );
    try {
      await updateDoc(settingsDoc, { ...data });
      firebaseAnalytics('update_settings', { msg: 'Update Settings' });
    } catch (error) {
      console.error('Error updating settings: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateSettings',
      });
    }
  };

  // Function to update account preferences
  const updateAccountPrefs = async (newPrefs) => {
    const settings = lists.find((list) => list.id === 'Settings');
    // Update user preferences in local state
    setAcctPrefs((prevState) => ({ ...prevState, ...newPrefs }));

    try {
      // Update preferences in the settings document
      await updateSettings({
        acctPrefs: { ...settings?.acctPrefs, ...newPrefs },
      });
      firebaseAnalytics('update_account_prefs', {
        msg: 'Update Account Preferences',
      });
      return true;
    } catch (error) {
      console.error('Error updating account preferences: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateAccountPrefs',
      });
      return false;
    }
  };

  // Function to update user preferences in the 'Settings' document and local state
  const updateUserPreffs = async (newPrefs) => {
    const email = currentUser.email.replace(/\./g, '_');
    const settings = lists.find((list) => list.id === 'Settings');
    // Update user preferences in local state
    setUserPreffs((prevState) => ({
      ...prevState,
      ...newPrefs,
    }));

    try {
      await updateSettings({
        [`userPrefs.${email}`]: {
          ...settings?.userPrefs[email],
          ...newPrefs,
        },
      });
      firebaseAnalytics('update_user_prefs', {
        msg: 'Update User Preferences',
      });
    } catch (error) {
      console.error('Error updating user preferences: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateUserPreffs',
      });
    }
  };

  const navigate = useNavigate();
  const logout = async (path) => {
    // console.log('run logout...');
    try {
      await signOut(auth);
      navigate(path);
    } catch (error) {
      console.error(error);
    }
  };

  // get the version from the DB and compare to the version currently running
  const confirmVersion = async () => {
    try {
      const inMemoryVersion = global.config.version;
      const versionDocRef = doc(db, 'config', 'appVersion');
      const versionData = (await getDoc(versionDocRef)).data();
      const onDiskVersion = versionData.version;
      setVersionMatch(onDiskVersion === inMemoryVersion);
      localStorage.setItem('vck', new Date());
      // console.log('inMemoryVersion:', inMemoryVersion);
      // console.log('onDiskVersion:', onDiskVersion);
    } catch (error) {
      console.error(error);
    }
  };

  // see if it's time to confirm the running version
  const checkVersion = () => {
    const lastCheck = localStorage.getItem('vck');
    if (lastCheck) {
      const lastCheckDate = new Date(lastCheck);
      const now = new Date();
      // return if less than 24 hours since the last check
      if (now.getTime() - lastCheckDate.getTime() < 24 * 60 * 60 * 1000) return;
    }
    confirmVersion();
  };

  // poll the version for changes on every page change
  // console.log('polling version...');
  checkVersion();

  // --------------------------------------------------------------------------
  // HISTORY
  // --------------------------------------------------------------------------

  // function to get the meal history of a given type
  // REQUIRES AN INDEX to use both where and orderBy in the same query
  // const getHistoryByType = (type) => {
  //   const history = getDocs(
  //     query(
  //       historyCollection,
  //       where('type', '==', type),
  //       orderBy('timestamp', 'desc')
  //     )
  //   );
  //   return history;
  // };

  const loadHistory = async () => {
    try {
      const historyCollectionRef = collection(
        db,
        'accounts',
        currentAccount,
        'history'
      );
      // verify that the collection exists
      const history = await getDocs(historyCollectionRef);
      const historyList =
        history.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) || [];
      setHistory(historyList);
      firebaseAnalytics('load_history', {
        msg: 'Load History',
        size: historyList.length,
      });
    } catch (error) {
      console.error('Error loading history: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'loadHistory',
      });
    }
  };

  const updateHistory = async (eventType, data) => {
    try {
      // Add a new document to the 'history' collection with the week number and shopping item data
      await addDoc(collection(db, 'accounts', currentAccount, 'history'), {
        timestamp: new Date(),
        event_type: eventType,
        event_data: data,
      });
      firebaseAnalytics('update_history', {
        msg: 'Update History',
        event_type: eventType,
      });
    } catch (error) {
      console.error('Error adding item: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'updateHistory',
      });
    }
  };

  const bulkUpdateHistory = async (eventType, data) => {
    const batch = writeBatch(db);
    try {
      data.forEach((docData) => {
        const docRef = doc(
          collection(db, 'accounts', currentAccount, 'history')
        );
        batch.set(docRef, docData);
      });
      await batch.commit();
      firebaseAnalytics('bulk_update_history', {
        msg: 'Bulk Update History',
        event_type: eventType,
      });
    } catch (error) {
      console.error('Error adding item: ', error);
      firebaseAnalytics('exception', {
        description: error.code,
        fatal: true,
        functionName: 'bulkUpdateHistory',
      });
    }
  };

  // --------------------------------------------------------------------------
  // Initial fetch of account data, user preferences, and account preferences
  // --------------------------------------------------------------------------

  // fetch initial data from Firestore and set up real-time listeners
  useEffect(() => {
    if (!currentAccount) return;

    const fetchData = async () => {
      try {
        // Get references to the Firestore collections
        const menuCollection = collection(
          db,
          'accounts',
          currentAccount,
          'menus'
        );
        const mealCollection = collection(
          db,
          'accounts',
          currentAccount,
          'meals'
        );
        const itemsCollection = collection(
          db,
          'accounts',
          currentAccount,
          'items'
        );
        const listsCollection = collection(
          db,
          'accounts',
          currentAccount,
          'lists'
        );

        // Fetch data from all collections concurrently using Promise.all
        const [menusSnapshot, mealsSnapshot, itemsSnapshot, listsSnapshot] =
          await Promise.all([
            getDocs(menuCollection),
            getDocs(mealCollection),
            getDocs(itemsCollection),
            getDocs(listsCollection),
          ]);

        // Update state variables with fetched data
        setMenus(
          menusSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setMeals(
          mealsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setItems(
          itemsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setLists(
          listsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

        // Set loading to false after data is fetched
        setLoading(false);
        firebaseAnalytics('fetch_data', {
          num_menus: menusSnapshot.docs.length,
          num_meals: mealsSnapshot.docs.length,
          num_items: itemsSnapshot.docs.length,
          num_lists: listsSnapshot.docs.length,
        });
      } catch (error) {
        if (error.code === 'permission-denied') {
          // likely removed as an account member...
          logout('/login?fx=1');
        }
        console.error('Error fetching data: ', error);
        firebaseAnalytics('exception', {
          description: error.code,
          fatal: true,
          functionName: 'fetchData',
        });
      }
    };

    fetchData();

    // Set up real-time listeners for changes in Firestore collections
    const unsubscribeMenus = onSnapshot(
      collection(db, 'accounts', currentAccount, 'menus'),
      (snapshot) => {
        setMenus(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const unsubscribeMeals = onSnapshot(
      collection(db, 'accounts', currentAccount, 'meals'),
      (snapshot) => {
        setMeals(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const unsubscribeItems = onSnapshot(
      collection(db, 'accounts', currentAccount, 'items'),
      (snapshot) => {
        setItems(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const unsubscribeLists = onSnapshot(
      collection(db, 'accounts', currentAccount, 'lists'),
      (snapshot) => {
        setLists(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    // Cleanup listeners on component unmount to avoid memory leaks
    return () => {
      unsubscribeMenus();
      unsubscribeMeals();
      unsubscribeItems();
      unsubscribeLists();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  useEffect(() => {
    const fetchPrefs = async () => {
      const email = currentUser.email.replace(/\./g, '_');
      try {
        const settingsDoc = await getDoc(
          doc(db, 'accounts', currentAccount, 'lists', 'Settings')
        );

        // load the account preferences
        setAcctPrefs(settingsDoc.data().acctPrefs);

        // load the user preferences
        var prefs = settingsDoc.data().userPrefs;
        if (!prefs?.[email]) {
          await updateUserPreffs({ color_pref: 'dark' });
          setColorPref('dark');
        } else {
          setUserPreffs(prefs?.[email]);
          setColorPref(prefs?.[email]?.color_pref);
        }
      } catch (error) {
        if (error.code === 'permission-denied') {
          // likely removed as an account member...
          logout('/login?fx=1');
        }
        console.error('Error getting user preferences: ', error);
        firebaseAnalytics('exception', {
          description: error.code,
          fatal: true,
          functionName: 'fetchPrefs',
        });
      }
    };
    fetchPrefs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, currentUser.email]);

  useEffect(() => {
    // Check the version
    // console.log('checking version...');
    confirmVersion();
  }, []);

  // --------------------------------------------------------------------------
  // CONTEXT
  // --------------------------------------------------------------------------

  // Create an object 'values' containing all the data and functions to be shared via the context
  const values = {
    loading,
    firebaseAnalytics,
    userPreffs,
    updateUserPreffs,
    colorPref,
    setColorPref,
    acctPrefs,
    updateAccountPrefs,
    logout,
    checkVersion,
    versionMatch,
    menus,
    addNewMenu,
    updateMenu,
    updateMenuStartDate,
    meals,
    addMeal,
    updateMeal,
    deleteMeal,
    fetchMealByUrl,
    items,
    addItem,
    createItem,
    updateItem,
    deleteItem,
    getItemByName,
    lists,
    addListItem,
    bulkAddListItems,
    changeListItem,
    removeListItem,
    findMealsContainingItem,
    findItemsContainingStore,
    addStore,
    updateStore,
    removeStore,
    storeTypes,
    updateStoreSortOrder,
    history,
    loadHistory,
    updateHistory,
    bulkUpdateHistory,
  };

  // Provide the 'values' object to all child components through the DataContext
  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

// Export the DataProvider and DataContext to be used in other parts of the application
export { DataProvider, DataContext };
