import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getVertexAI, getGenerativeModel } from 'firebase/vertexai-preview';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// console.log('firebase config: ' + JSON.stringify(firebaseConfig));
// console.log('app env:', process.env.REACT_APP_FIREBASE_ENV);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();
export const functions = getFunctions(app);

// Initialize the Vertex AI service
const vertexAI = getVertexAI(app);
const generationConfig = {
  temperature: 1,
  maxOutputTokens: 4096,
  responseMimeType: 'application/json',
};
export const model = getGenerativeModel(vertexAI, {
  model: 'gemini-1.5-flash',
  generationConfig,
});
